import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import {
  GENDER_STATUS, INDEX, OBJECT_KEYS, ROLES, SURVEY_PROCESS_TYPE,
  MODULES, MODULE_ACTIONS, COMPANY_SLUGS,
} from "common/constants";
import { isEmpty, isEqual, isNull } from "common/helpers";
import {
  isValidAdmin as isValidAdminFunction,
  isValidRole,
  replacePath,
  isAdminOrSuccessionAdmin,
  isValidToKeyPositionAction,
} from "common/utils";
import {
  resetState as resetStateHistoricalMovements,
} from "redux/actions/historicalMovementActions";
import { getList as getGender } from "redux/actions/common/genderActions";
import {
  getOne as getCollaborator, resetState as resetStateCollaborator,
  resetStateOne as resetStateOneCollaborator, getSuccessionPlansByEmployeeId,
} from "redux/actions/collaboratorActions";
import { resetState as resetStateAcknowledgements } from "redux/actions/acknowledgementTypeActions";
import { resetStateReceived, resetStateSent } from "redux/actions/feedbackActions";
import { resetState as resetIndividualSurveyResult, getLastResultByProcessTypeAndEmployee } from "redux/actions/performance/surveyResultsActions";
import { resetState as resetPersonality } from "redux/actions/personalityActions";
import { resetState as resetStateDocumentList } from "redux/actions/collaborators/documentActions";
import { getCurrentTalentScoreByEmployee } from "redux/actions/talentScore/talentScoreActions";
import { getList as getResultScales } from "redux/actions/resultScalesActions";
import { getAgreementsPaginatedByEmployeeId } from "redux/actions/agreements/agreementActions";
import useComplexState from "hooks/utils/useComplexState";
import localRoutes from "../functions/routes";

export const useProfileReducerHook = (externalHooks) => {
  const {
    dispatch, t, location, history, user, ability,
  } = externalHooks;

  const { search } = location;

  const isAdmin = isValidAdminFunction(user?.userCookies);
  const isOnboardingAdmin = isValidRole(user?.userCookies, ROLES.ONBOARDING_ADMIN);
  const isTalentManager = isValidRole(user?.userCookies, ROLES.TALENT_MANAGER);
  const searchByCollaborator = search && search.includes(OBJECT_KEYS.collaborator);
  const paramsList = search.split("&");
  const employeeId = searchByCollaborator && paramsList ? paramsList[INDEX.zero].split("=")[INDEX.one] : user?.employee?.id;
  const isSuccessionsAdmin = isValidToKeyPositionAction(user);
  const isNalaAdmin = isValidRole(user?.userCookies, ROLES.ADMIN_NALA);
  const isValidToViewSuccessionPlan = isAdminOrSuccessionAdmin(user);

  // Ability
  const developmentPlanAbility = ability?.developmentPlan;
  let hasAccessDevelopmentPlan = false;
  if (developmentPlanAbility) {
    hasAccessDevelopmentPlan = developmentPlanAbility
      .can(MODULE_ACTIONS.view, MODULES.developmentPlan);
  }

  const [employeeStates, setEmployeeStates] = useComplexState({
    isFirstLoad: true,
  });
  const { list: allGenderList } = useSelector(
    ({ genderReducer }) => genderReducer,
  );
  const {
    one: collaborator,
    isLoadingOne: isLoadingCollaboratorReducer,
    errorOne,
    isLoadingProcess,
    successProcess,
    errorProcess,
  } = useSelector((state) => state.collaboratorReducer);

  const {
    list: resultScaleList,
    isLoadingList: isLoadingScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  useEffect(() => {
    dispatch(getResultScales());
  }, [dispatch]);

  const dispatchCollaborator = () => {
    dispatch(getCollaborator(employeeId));
    dispatch(resetStateHistoricalMovements());
    dispatch(resetStateAcknowledgements());
    dispatch(resetStateReceived());
    dispatch(resetStateSent());
    dispatch(resetIndividualSurveyResult());
    dispatch(resetPersonality());
    dispatch(resetStateDocumentList());
  };

  const dispatchResetCollaborator = () => {
    dispatch(resetStateOneCollaborator());
  };

  useEffect(() => {
    if (employeeId) {
      if ((isNull(collaborator) || !isEqual(collaborator?.id, Number(employeeId)))
        && isEmpty(errorOne)
        && !isLoadingCollaboratorReducer
        && employeeStates.isFirstLoad
      ) {
        dispatchCollaborator();
        setEmployeeStates({ isFirstLoad: false });
      }
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [
    collaborator,
    dispatch,
    employeeId,
    history,
    isLoadingCollaboratorReducer,
    employeeStates.isFirstLoad,
  ]);

  useEffect(() => {
    if (!allGenderList) {
      dispatch(getGender());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search && !searchByCollaborator) {
      const lastHistory = history;
      lastHistory.location.search = "";
      replacePath(lastHistory, localRoutes.root);
    }
    // eslint-disable-next-line
  }, []);

  const genderList = allGenderList?.map((item) => (
    { ...item, value: t(GENDER_STATUS[item.id]), label: t(GENDER_STATUS[item.id]) }
  ));

  const isOwnProfile = isEmpty(search) || isEqual(user?.employee?.id, Number(employeeId));
  const collaboratorIsManager = collaborator && !isNull(collaborator?.roles) ? collaborator.roles.some((rol) => isEqual(rol.name, ROLES.MANAGER)) : false;
  const hasPermissionAsTalentManager = isTalentManager && !isOwnProfile;
  const isValidToShowAttritionAndNinebox = isAdmin || hasPermissionAsTalentManager || collaborator?.is_descendant || (!isOwnProfile && !isOnboardingAdmin);
  const isValidToShowSalary = isAdmin || collaborator?.is_descendant || isOwnProfile;

  const hasAccessToViewPotential = !(
    isOwnProfile && !user.company.show_potential
  );
  const onCloseModals = () => {
    dispatch(resetIndividualSurveyResult());
    history.location.search = history?.location?.search.split("&")[INDEX.zero];
    replacePath(history, localRoutes.root);
  };

  const { data: currentTalentScore, isLoading: isLoadingCurrentTalentScore } = useQuery(
    ["currentTalentScore", employeeId],
    () => getCurrentTalentScoreByEmployee(employeeId),
    {
      staleTime: Infinity,
      enabled: isValidToShowAttritionAndNinebox,
    },
    [employeeId],
  );

  const { data: currentPerformance, isLoading: isLoadingCurrentPerformance } = useQuery(
    ["currentPerformance", employeeId],
    () => getLastResultByProcessTypeAndEmployee(employeeId, SURVEY_PROCESS_TYPE.performance.key),
    {
      staleTime: Infinity,
    },
    [employeeId],
  );

  const { data: currentPotential, isLoading: isLoadingCurrentPotential } = useQuery(
    ["currentPotential", employeeId],
    () => getLastResultByProcessTypeAndEmployee(employeeId, SURVEY_PROCESS_TYPE.potential.key),
    {
      staleTime: Infinity,
      enabled: hasAccessToViewPotential,
    },
    [employeeId],
  );

  const { data: successionPlansByEmployee, isLoading: isLoadingSuccessionPlans } = useQuery(
    ["successionPlansByEmployee", employeeId],
    () => getSuccessionPlansByEmployeeId(employeeId),
    {
      staleTime: Infinity,
      enabled: isValidToViewSuccessionPlan,
    },
    [employeeId],
  );

  const {
    data: agreementsCollaboratorList,
    isLoading: isLoadingAgreementsCollaboratorList,
    error: agreementsCollaboratorListError,
  } = useQuery(
    ["agreementsCollaboratorList", employeeId],
    () => getAgreementsPaginatedByEmployeeId(employeeId, 3),
    {
      staleTime: Infinity,
      enabled: hasAccessDevelopmentPlan,
    },
    [employeeId],
  );

  return {
    genderList,
    collaborator,
    searchCollaborator: Number(employeeId),
    isLoadingCollaboratorReducer,
    isOwnProfile,
    isManager: collaboratorIsManager,
    isAdmin,
    isValidToShowSalary,
    isValidToShowAttrition: isValidToShowAttritionAndNinebox,
    isValidToShowNineBox: isValidToShowAttritionAndNinebox,
    hasAuthorization: isEmpty(errorOne),
    canEditPersonalData: (isOwnProfile || isAdmin) && isEmpty(errorOne),
    process: {
      successProcess,
      errorProcess,
      isLoadingProcess,
    },
    dispatchCollaborator,
    dispatchResetCollaborator,
    resetStateCollaborator,
    onCloseModals,
    currentTalentScore,
    isLoadingCurrentTalentScore,
    currentPerformance,
    isLoadingCurrentPerformance,
    currentPotential,
    isLoadingCurrentPotential,
    resultScales: {
      resultScaleList,
      isLoadingScaleList,
    },
    isSuccessionsAdmin,
    isNalaAdmin,
    isValidToViewSuccessionPlan,
    successionPlan: {
      successionPlansByEmployee,
      isLoadingSuccessionPlans,
    },
    agreements: {
      agreementsCollaboratorList,
      isLoadingAgreementsCollaboratorList,
      agreementsCollaboratorListError,
    },
    hasAccessDevelopmentPlan,
  };
};

import styled from "styled-components";

export const StyledSelect = styled.div`
  .MuiSelect-select {
    width: 150px;
  }

  @media (max-width: 950px) {
    .MuiSelect-select {
      width: 100%;
    }
  }

  .select-wrapper {
    position: relative;
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 1;
  }
`;

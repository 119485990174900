import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as InfoIcon } from "assets/images/general/info.svg";
import {
  ExplanatoryContainer, IconWrapper, TitleWrapper, ContentWrapper,
} from "./styles";

const InfoBox = ({ title, message }) => (
  <ExplanatoryContainer id={ "info-box" }>
    <TitleWrapper>
      <IconWrapper>
        <InfoIcon />
      </IconWrapper>
      <div>
        {title.split("\n").map((line) => (
          <h4 key={ line }>{line}</h4>
        ))}
      </div>
    </TitleWrapper>
    {message && (
      <ContentWrapper>
        {message.split("\n").map((line) => (
          <p key={ line }>{line}</p>
        ))}
      </ContentWrapper>
    )}
  </ExplanatoryContainer>
);

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default InfoBox;

import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  getAllResultsLabels,
  transformProcessData,
  getProcessColor,
  hexToRgba,
} from "./functions";
import {
  ChartContainer,
  Button,
  ButtonGroup,
  ChartWrapper,
} from "./styles";

const GaussChart = ({
  indices, processes, defaultProcessId, onFilterProcesses,
}) => {
  const [allLabels, setAllLabels] = useState([]);
  const [processMap, setProcessMap] = useState({});
  const [selectedProcesses, setSelectedProcesses] = useState({});
  const chartRef = useRef(null);

  const { t } = useTranslation("performance");

  // Initialize processes and selection state
  useEffect(() => {
    const latestProcess = processes.length - 1;
    const initialActiveProcess = defaultProcessId
      ? processes.find((p) => p.id === defaultProcessId)
      : latestProcess;

    const initialSelected = processes.reduce((acc, cur) => {
      acc[cur.name] = cur.id === initialActiveProcess?.id;
      return acc;
    }, {});

    setSelectedProcesses(initialSelected);
    setAllLabels(getAllResultsLabels(indices));
    setProcessMap(transformProcessData(processes, indices));
  }, [defaultProcessId, indices, processes]);

  // Check if there's at least one invalid process
  const hasInvalidProcess = Object.values(processMap).some(
    (process) => process.invalid,
  );

  // Filtered processes to sync with parent
  useEffect(() => {
    const filtered = processes.filter(
      (process) => selectedProcesses[process.name],
    );
    if (onFilterProcesses) {
      onFilterProcesses(filtered);
    }
  }, [selectedProcesses, processes, onFilterProcesses]);

  const handleProcessToggle = (name) => {
    setSelectedProcesses((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const datasets = Object.keys(selectedProcesses)
    .filter((key) => selectedProcesses[key])
    .map((key) => {
      const subObj = processMap[key] || {};
      const dataArray = allLabels.map((label) => subObj[label] || 0);
      const isInvalid = !!processMap[key]?.invalid;
      const processColor = getProcessColor(key, isInvalid);
      const processIndex = processes.findIndex((p) => p.name === key);
      const total = processes.length - 1;
      const alphaRange = 0.3;
      const alpha = total <= 0 ? 0.39 : 0.39 - (processIndex / total) * alphaRange;
      return {
        label: key,
        data: dataArray,
        backgroundColor: hexToRgba(processColor, alpha),
        borderColor: "transparent",
        fill: true,
        pointRadius: 6,
        pointHoverRadius: 6,
        pointBackgroundColor: processColor,
      };
    });

  const data = { labels: allLabels, datasets };

  const allSelectedData = datasets.flatMap((ds) => ds.data);
  const maxValue = allSelectedData.length ? Math.max(...allSelectedData) : 0;
  const dynamicSuggestedMax = maxValue + 10;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
          ticks: {
            callback(value) {
              if (value.length > 18) {
                return `${value.substring(0, 18)}...`;
              }
              return value;
            },
            fontSize: 12,
            padding: 5,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: { display: false },
          ticks: { suggestedMax: dynamicSuggestedMax },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title(tooltipItem) {
          const labelIndex = tooltipItem[0].index;
          return data.labels[labelIndex];
        },
        label: (tooltipItem) => `${tooltipItem.yLabel}%`,
      },
    },
    legend: { display: false },
    elements: {
      line: {
        tension: 0.3,
        borderWidth: 0,
      },
    },
    hover: {
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
    },
  };

  return (
    <ChartContainer>
      {hasInvalidProcess && (
        <p style={ { margin: "8px 0", color: "red", fontSize: "12px" } }>
          {t("gauss_chart.info")}
        </p>
      )}
      <ButtonGroup>
        {processes.map((process) => {
          const { name } = process;
          const isInvalid = !!processMap[name]?.invalid;

          return (
            <Button
              key={ name }
              onClick={ () => (!isInvalid && process.id !== defaultProcessId
                ? handleProcessToggle(name)
                : null) }
              selected={ selectedProcesses[name] }
              color={ getProcessColor(name, isInvalid) }
              disabled={ isInvalid }
            >
              {name}
            </Button>
          );
        })}
      </ButtonGroup>
      <ChartWrapper>
        <Line ref={ chartRef } data={ data } options={ options } />
      </ChartWrapper>
    </ChartContainer>
  );
};

GaussChart.propTypes = {
  indices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      result: PropTypes.string.isRequired,
      result_es: PropTypes.string,
      result_en: PropTypes.string,
      result_pt: PropTypes.string,
      description: PropTypes.string,
      description_es: PropTypes.string,
      description_en: PropTypes.string,
      description_pt: PropTypes.string,
    }),
  ).isRequired,
  processes: PropTypes.array.isRequired,
  defaultProcessId: PropTypes.number,
  onFilterProcesses: PropTypes.func,
};

GaussChart.defaultProps = {
  defaultProcessId: null,
  onFilterProcesses: null,
};

export default GaussChart;

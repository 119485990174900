import styled from "styled-components";

export const ChartContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const ButtonGroup = styled.div`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 8px;
  font-size: 12px;
  cursor: pointer;
  background-color: transparent;
  color: ${(props) => (props.selected ? props.color : "#aaa")};
  border: 1px solid ${(props) => (props.selected ? props.color : "#aaa")};
  border-radius: 20px;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

  &:before {
    content: "•";
    font-size: 12px;
    color: ${(props) => (props.selected ? props.color : "#aaa")};
  }

  &:hover {
    color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};

    &:before {
      color: ${(props) => props.color};
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: #aaa;
    border: 1px dashed #ccc;
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

export const StyledPaperContainer = styled(Paper)`
  padding: 32px;
  margin-top: 16px;
  margin-bottom: 25px;
`;

export const StyledTitleText = styled(Typography)`
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${palette.black};
`;

export const StyledSubtitleText = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${palette.subtitle};
`;

export const StyledGridHeatMap = styled(Grid)`
  direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledGridContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledGridCards = styled(Grid)`
  margin-top: 5px;
`;

export const StyledGridButton = styled(Grid)`
  margin-right: ${(props) => (props.isModal && "15px")};
  float: ${(props) => (props.isModal && "right")};
  margin-top: ${(props) => (props.isModal ? "20px" : "5px")};
  svg {
    display: ${(props) => (props.isModal && "none")};
  }
  .MuiButtonBase-root{
    background: ${(props) => (props.isModal && palette.primaryApp)};
    color: ${(props) => (props.isModal && palette.white)};
  }
  .MuiButton-outlinedSizeSmall{
    padding: ${(props) => (props.isModal && "5px 10px !important")};
  }
  .buttonIconContainer .left {
    margin-right: ${(props) => (props.isModal && "5px")};
  }
`;

export const StyledGridParticipation = styled(Grid)`
  width: 97%;
`;

export const StyledRanking = styled.div`
  .MuiPaper-root {
    margin-top: 15px;
    padding: 15px;
  }
`;

export const StyledColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const StyledComparative = styled(Grid)`
  .MuiTableCell-head {
    padding: 0 6px;
  }
  .MuiTableCell-root {
    font-size: 12px;
    padding: 0;
    padding-right: 5px;
  }
  .MuiButtonBase-root .MuiTableSortLabel-icon {
    font-size: 0.8rem;
    margin-left: 10px;
  }
  #info-box {
    margin-top: 20px;
  }
`;
export const StyledButtonGroup = styled(ButtonGroup)`
  background: #C4C4C4;
  border-radius: 100px;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: 500;
  border: 1px solid;
  border-color: ${(props) => (props.active ? props.theme.palette.primaryApp : "transparent")} !important;
  color: ${(props) => (props.active ? props.theme.palette.primaryApp : "#4F4F4F")};
  background-color: ${(props) => (props.active ? props.theme.palette.background.paper : "transparent")};
  text-transform: none;
  padding: 8px 10px;
  border-radius: 100px !important;
  transition: padding 0.3s ease, margin 0.3s ease;
  width: 200px;
  height: 36px;
  &:hover {
    border-color: ${(props) => (props.active ? props.theme.palette.primaryApp : "transparent")};
    background-color: ${(props) => (props.active ? props.theme.palette.white : "transparent")};
  }
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
`;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useUserAbility from "hooks/useUserAbility";
import Select from "components/Select";
import Modal from "components/Modal";
import { CircularProgress } from "@mui/material";
import {
  MESSAGE_TYPES,
  toast,
} from "components/Toast/functions";
import {
  COLLABORATORS_PATH,
  FEEDBACK_PATH,
  MOVEMENTS_PATH,
  ROLES,
  MODULE_ACTIONS,
  MODULES,
  FILE_TYPE_EXCEL,
} from "common/constants";
import {
  mainDownloadExcel,
  getEmployeeCompanyId,
  isValidRole,
  isValidAdmin,
  getEmployeeCompanyName,
  formatDate,
} from "common/utils";
import {
  downloadTalentFramework,
  getDownloadExcel,
} from "redux/actions/collaboratorActions";
import { resetStateToDownload as resetStateMovementsToDownload } from "redux/actions/historicalMovementActions";
import useFeedbacks from "../../hooks/useFeedbacks";
import {
  getDataExcelFeedbacks,
  getDataExcelMovements,
} from "../../functions/collaborators";
import MovementDownloadModal from "../MovementDownloadModal";
import { StyledSelect } from "./styles";

const DownloadExcel = (props) => {
  const { user, query } = props;
  let hasAccessAcknowledgments = false;
  const ability = useUserAbility(user);
  if (ability && ability?.acknowledgments) {
    const acknowledgementAbility = ability?.acknowledgments;
    hasAccessAcknowledgments = acknowledgementAbility?.can(
      MODULE_ACTIONS.view, MODULES.acknowledgments,
    );
  }
  const { t } = useTranslation(["collaborators", "common", "feedback"]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    downloadlist: downloadMovementsList,
    isLoadingDownloadList: isLoadingDownloadMovementsList,
  } = useSelector(({ historicalMovementsReducer }) => historicalMovementsReducer);

  const isTalentManager = isValidRole(user?.userCookies, ROLES.TALENT_MANAGER);
  const isAdmin = isValidAdmin(user?.userCookies);
  const isManager = isValidRole(user?.userCookies, ROLES.MANAGER);
  const isOnlyManager = isManager && !(isAdmin || isTalentManager);
  const companyId = getEmployeeCompanyId(user);
  const companyName = getEmployeeCompanyName(user);
  const today = new Date();
  const [isLoadingCollaboratorsExcel, setIsLoadingCollaboratorsExcel] = useState(false);

  useEffect(() => {
    if (!isLoadingDownloadMovementsList && downloadMovementsList) {
      const dataToExcel = getDataExcelMovements(downloadMovementsList, t);
      const { date_gteq, date_lteq } = query.q;
      mainDownloadExcel(dataToExcel, `${t("common:fileNames.movements")}_${date_gteq}_${date_lteq}`);
      dispatch(resetStateMovementsToDownload());
    }
  }, [
    downloadMovementsList,
    isLoadingDownloadMovementsList,
    query.q,
    t,
    dispatch,
  ]);

  const getDownloadTypes = () => {
    const types = [];
    if (!isOnlyManager) {
      types.push({ value: COLLABORATORS_PATH, label: t("common:common.download.collaborators") });
    }
    if (isAdmin) {
      types.push({ value: MOVEMENTS_PATH, label: t("common:common.download.movements") });
    }
    if (hasAccessAcknowledgments && !isOnlyManager) {
      types.push({ value: FEEDBACK_PATH, label: t("common:common.download.feedback") });
    }
    if (isAdmin || isTalentManager || isManager) {
      types.push({ value: "talentFramework", label: "Talent Framework" });
    }
    return types;
  };

  const queryFeedbackKey = ["feedbacks"];
  const [isDownloadFeedbacks, setIsDownloadFeedbacks] = useState(false);

  const {
    feedbacks,
    isLoadingFeedbacks,
    isFetchingFeedbacks,
    refetchFeedbacks,
  } = useFeedbacks(query, queryFeedbackKey);

  useEffect(() => {
    if (isDownloadFeedbacks && feedbacks.length > 0 && !isFetchingFeedbacks) {
      const dataToExcel = getDataExcelFeedbacks(feedbacks, t);
      mainDownloadExcel(dataToExcel, t("common:fileNames.feedbacks"));
      setIsDownloadFeedbacks(false);
    }
  }, [
    isDownloadFeedbacks,
    feedbacks,
    isFetchingFeedbacks,
    t,
  ]);

  const handleSendExcel = async () => {
    const asyncTask = await dispatch(downloadTalentFramework(query, isOnlyManager));
    if (asyncTask?.aasm_state === "pending") {
      toast(MESSAGE_TYPES.info, {
        title: t("administrator:modules.positions.download.title"),
        message: t("administrator:modules.positions.download.message"),
      });
    }
  };

  const handleDownload = async (prop, event) => {
    const { value } = event.target;

    if (value === COLLABORATORS_PATH) {
      setIsLoadingCollaboratorsExcel(true);
      const response = await getDownloadExcel(query, companyId);
      setIsLoadingCollaboratorsExcel(false);

      if (response?.data) {
        const blob = new Blob([response.data], { type: FILE_TYPE_EXCEL });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.download = `${t("common:sidebar.employees")}_${companyName}_${formatDate(today, "YYYY-MM-DD")}.xlsx`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } else if (value === FEEDBACK_PATH) {
      setIsDownloadFeedbacks(true);
      refetchFeedbacks();
    } else if (value === "talentFramework") {
      handleSendExcel();
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <StyledSelect>
      <div className={ "select-wrapper" }>
        <Select
          label={ t("common:common.downloadAction") }
          menuItems={ getDownloadTypes() }
          onChange={ handleDownload }
          className={ "custom-select" }
          disabled={ isLoadingCollaboratorsExcel || isLoadingFeedbacks || isLoadingDownloadMovementsList }
        />
        {(isLoadingCollaboratorsExcel || isLoadingFeedbacks || isLoadingDownloadMovementsList) && (
          <div className={ "loading-spinner" }>
            <CircularProgress size={ 24 } />
          </div>
        )}
      </div>

      <Modal
        isOpen={ isOpen }
        onClose={ () => setIsOpen(false) }
        title={ t("common:common.download.movements") }
      >
        <MovementDownloadModal modalHandler={ setIsOpen } query={ query } />
      </Modal>
    </StyledSelect>
  );
};

DownloadExcel.propTypes = {
  user: PropTypes.object.isRequired,
  query: PropTypes.object,
};

DownloadExcel.defaultProps = {
  query: {},
};

export default DownloadExcel;

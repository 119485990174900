import {
  useState,
  useContext,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { clarity } from "react-microsoft-clarity";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import NoDataMessage from "components/NoDataMessage";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import DownloadExcelButton from "components/DownloadExcelButton";
import usePagination from "hooks/utils/usePagination";
import { SessionContext } from "modules/session/context";
import {
  COLORS_NAME,
  COMPONENT,
  ALIGN_ITEMS,
  PAGINATION,
  ROLES,
  TRACKING_TAGS,
  MODULES,
  VARIANT,
} from "common/constants";
import {
  isAdminOrManager as isAdminOrManagerFunction,
  isManager as isManagerFunction,
  isValidRole,
  isOnlyCollaborator,
  mainDownloadExcel,
  isAdmin,
} from "common/utils";
import { getListPaginated as getGoalListPaginated, getListTotal } from "redux/actions/goalsActions";
import { Grid } from "@mui/material";
import { getHeader, getRowsDesktop, getFormattedDownloadData } from "../../functions";
import CollapseGoalsTable from "../CollapseGoalsTable";
import { SwitchStyled, StyledEmailIcon, StyledButton } from "./styles";
import ReminderModal from "../ReminderModal";

const TableGoals = (props) => {
  const { isGeneral, filterQuery, isUpdateAllowed, filterName, isEditingDisabled } = props;
  const { t } = useTranslation(["tables", "common", "collaborator", "goals"]);
  const dispatch = useDispatch();
  const [isReminderModalOpen, setReminderModalOpen] = useState(false);

  const {
    list: goalsList,
    generalList: generalGoalsList,
    isLoadingList: isLoadingGoalsList,
    rulesList: rules,
    listTotal: goalsListTotal,
  } = useSelector(({ goalsReducer }) => goalsReducer);

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdminOrManager = isAdminOrManagerFunction(user?.userCookies);
  const hasValidRole = isAdminOrManager || isValidRole(user?.userCookies, ROLES.TALENT_MANAGER);
  const isManager = isManagerFunction(user?.userCookies);
  const isOnlyCollaboratorRole = isOnlyCollaborator(user?.userCookies);
  const hasAdminRole = isAdmin(user?.userCookies);

  const [active, setActive] = useState(null);
  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [searchFilter, setSearchFilter] = useState(filterName);
  const [dataToDownload, setDataToDownload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getCollapseData = (item) => (
    item.goals?.length === 0 && isGeneral
      ? <NoDataMessage /> : (
        <CollapseGoalsTable
          data={ item }
          rules={ rules }
          isGeneral={ isGeneral }
          isUpdateAllowed={ isUpdateAllowed }
          isEditingDisabled={ isEditingDisabled }
        />
      )
  );

  const handleChange = () => {
    setActive(!active);
  };

  const handleSearch = (name = "") => {
    setSearchFilter(name);
  };

  const getDataToDownload = async () => {
    if (filterQuery.q || isOnlyCollaboratorRole) {
      setIsLoading(true);
      const goalsData = [];

      let currentPage = 1;
      const pageSize = 50;

      let hasMorePages = true;

      while (hasMorePages) {
        const query = {
          q: {
            ...filterQuery.q,
          },
          page: {
            number: currentPage,
            size: pageSize,
          },
        };

        if (active) {
          query.q.manager_id_eq = user.employee?.id;
        }

        // eslint-disable-next-line no-await-in-loop
        const goalList = await dispatch(getListTotal(query, isGeneral));

        if (!goalList || goalList.length === 0) {
          hasMorePages = false;
        } else {
          goalsData.push(...goalList);
          if (goalList.length < pageSize) {
            hasMorePages = false;
          } else {
            currentPage += 1;
          }
        }
      }

      setDataToDownload(getFormattedDownloadData(t, goalsData));
    }
  };

  const handleDownloadExcel = () => {
    getDataToDownload();
  };

  const handleOpenReminderModal = () => {
    setReminderModalOpen(true);
  };

  const handleCloseReminderModal = () => {
    setReminderModalOpen(false);
  };

  const getNewData = (actualPage) => {
    if (filterQuery.q || isOnlyCollaboratorRole) {
      const query = {
        q: {
          ...filterQuery.q,
          ...{
            person_full_name_cont: searchFilter || "",
          },
        },
      };
      if (!isGeneral) {
        query.q.end_date_gteq = new Date();
      }
      if (active) {
        query.q.manager_id_eq = user.employee?.id;
      }
      if (!isLoadingGoalsList) {
        dispatch(getGoalListPaginated(actualPage, query));
      }
    }
  };

  useEffect(() => {
    if ((pagination > 1 && !isLoadingGoalsList) || pagination === 1) {
      getNewData(pagination);
    }
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    setPagination(PAGINATION.next);
    getNewData(PAGINATION.next);
  // eslint-disable-next-line
  }, [searchFilter, filterQuery, active]);

  useEffect(() => {
    if (dataToDownload?.length > 0) {
      mainDownloadExcel(dataToDownload, t("goals:title"), true);
      setIsLoading(false);
    }
  // eslint-disable-next-line
  }, [dataToDownload]);

  return (
    <div data-testid={ "table-goals" }>
      {isManager && (
        <div>
          <FormControl component={ COMPONENT.fieldset }>
            <FormGroup>
              <FormControlLabel
                control={ (
                  <SwitchStyled
                    color={ COLORS_NAME.primary }
                    checked={ active }
                    onChange={ handleChange }
                  />
                ) }
                label={ t("tables:actions.view_team") }
              />
            </FormGroup>
          </FormControl>
        </div>
      )}

      <TablePaginationCollapse
        header={ getHeader(t) }
        list={ isGeneral ? generalGoalsList : goalsList }
        isLoading={ isLoadingGoalsList }
        getRows={ {
          mobile: getRowsDesktop,
          desktop: getRowsDesktop,
        } }
        handlePagination={ handlePagination }
        pagination={ pagination }
        total={ goalsListTotal }
        handleSearch={ hasValidRole ? handleSearch : "" }
        modalOptions={ [getCollapseData] }
        rightOptions={hasValidRole && (
          <Grid container spacing={ 1 } alignItems={ ALIGN_ITEMS.center }>
            <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center } gap={ 2 }>
              <DownloadExcelButton
                isDisabled={isLoadingGoalsList || isLoading || goalsListTotal.length === 0}
                onClick={ handleDownloadExcel }
                isDownloadApart
              />
              {hasAdminRole && (
                <StyledButton
                  variant={ VARIANT.outlined }
                  onClick={ handleOpenReminderModal }
                >
                  <StyledEmailIcon />
                  {t("goals:reminder")}
                </StyledButton>
              )}
            </Box>
            <ReminderModal open={ isReminderModalOpen } onClose={ handleCloseReminderModal } t={ t } />
          </Grid>
        ) }
        defaultSearchValue={ filterName }
      />
    </div>
  );
};

TableGoals.propTypes = {
  isGeneral: PropTypes.bool.isRequired,
  filterQuery: PropTypes.object,
  isUpdateAllowed: PropTypes.bool,
  filterName: PropTypes.string,
  isEditingDisabled: PropTypes.bool,
};

TableGoals.defaultProps = {
  filterQuery: {},
  isUpdateAllowed: true,
  filterName: null,
  isEditingDisabled: false,
};

export default TableGoals;

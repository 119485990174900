import qs from "qs";
import isEmpty from "lodash/isEmpty";
import { ORG_UNITS, LOCAL_STORAGE_NAMES, OBJECT_KEYS, PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import { getItemFromLocalStorage, getUserId, getDivisionAndAreaType } from "common/utils";
import { getList as getOrgUnitsList } from "redux/actions/common/orgUnitActions";
import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
} from "../../actionTypes/performance/heatMap";

const URL = {
  main: "/survey_processes",
  heatMap: "heat_map",
  heatMapByDepartment: "heat_map_by_department",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getListByDepartment = (processId, query) => async (dispatch, getState) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const HEAT_MAP_BY_DEPARTMENT_URL = `${URL.main}/${processId}/${URL.heatMapByDepartment}`;
  const organizationUnitsTypes = getDivisionAndAreaType();
  const orgUnitTypeDivisionId = organizationUnitsTypes?.division?.id;

  try {
    await dispatch(getOrgUnitsList());
    const { list: organizationUnits } = getState().orgUnitReducer;
    const departments = organizationUnits
      .filter(
        (department) => department.organization_unit_type_id === orgUnitTypeDivisionId
          && department.name?.trim(),
      )
      .map((department) => department.name.trim());

    const aggregatedHeatMapData = await departments.reduce(
      async (accPromise, department) => {
        const acc = await accPromise;
        try {
          const params = {
            ...query,
            department,
            [OBJECT_KEYS.userId]: userId,
          };

          const response = await configAxios.get(HEAT_MAP_BY_DEPARTMENT_URL, {
            params,
            paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
          });

          return acc.concat(response.data || []);
        } catch (error) {
          console.error(`Error fetching data for department: ${department}`, error);
          return acc;
        }
      },
      Promise.resolve([]),
    );

    const sortedData = aggregatedHeatMapData.sort((a, b) => a.name.localeCompare(b.name));

    dispatch({
      type: GET_LIST,
      payload: sortedData,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getList = (processId, filter, query) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));

  const filterSearch = filter !== ORG_UNITS.division ? filter.toLowerCase() : "";
  const HEAT_MAP_URL = `${URL.main}/${processId}/${URL.heatMap}`;

  dispatch({ type: GET_LIST_LOADING });

  try {
    if (isEmpty(filterSearch)) {
      await dispatch(getListByDepartment(processId, query));
    } else {
      const newQuery = {
        ...query,
        q: { ...query.q, s: "score desc" },
      };

      const params = { ...newQuery, [OBJECT_KEYS.userId]: userId };
      params.by = filterSearch;

      const response = await configAxios.get(HEAT_MAP_URL, {
        params,
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });

      dispatch({
        type: GET_LIST,
        payload: response.data,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

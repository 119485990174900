import { useState } from "react";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SORT_COMPARATOR } from "common/constants";
import { isEqual } from "common/helpers";
import {
  StyledTableCell, StyledCollapseContainer, StyledTableAddCell, StyledRowTableCell,
} from "../../styles";

const TableRowDesktop = (props) => {
  const {
    item,
    rowsOptions,
    data,
    actions,
    beforeIconCollapseData,
    header,
    hidden,
    rowIndex,
    expandFirstRow,
  } = props;

  const [isOpen, setIsOpen] = useState(rowIndex === 0 && expandFirstRow);
  const isEditable = data && actions && isEqual(data?.id, actions?.editableRow?.rowData?.id);
  const collapseDataIndex = item.findIndex((cell) => cell.collapseData);
  const collapseContent = !isEqual(collapseDataIndex, SORT_COMPARATOR.minusOne)
    && (
      <TableRow key={ `collapse${item}` }>
        <StyledTableCell colSpan={ header.length }>
          <Collapse in={ isOpen } unmountOnExit>
            {item[collapseDataIndex].collapseData}
          </Collapse>
        </StyledTableCell>
      </TableRow>
    );

  const getValueToRender = actions && actions?.editableRow && isEditable
    && actions?.editableRow?.action.getEditableRow(actions?.editableRow?.rowData);

  return (
    <>
      <TableRow className={ `row-${rowIndex}` }>
        { actions?.editableRow && isEditable
          ? (
            <StyledTableAddCell colSpan={ rowsOptions.length }>
              {getValueToRender}
            </StyledTableAddCell>
          ) : item?.map((cell, index) => {
            const { key } = header[index];
            const isDisabled = key && hidden.hasOwnProperty.call(hidden, key) && hidden[key];
            return (
              <StyledRowTableCell
                key={ `cell${header[index]?.id}` }
                disabled={ isDisabled }
              >
                <StyledCollapseContainer>
                  {cell?.collapseData && (
                    <IconButton onClick={ () => setIsOpen(!isOpen) }>
                      { beforeIconCollapseData
                      && <img src={ beforeIconCollapseData } alt={ "Collapse Icon" } />}
                      {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
                  {cell.content}
                </StyledCollapseContainer>
              </StyledRowTableCell>
            );
          })}
      </TableRow>
      { collapseContent }
    </>
  );
};

TableRowDesktop.propTypes = {
  rowsOptions: PropTypes.array.isRequired,
  item: PropTypes.array.isRequired,
  data: PropTypes.object,
  actions: PropTypes.object,
  beforeIconCollapseData: PropTypes.string,
  header: PropTypes.array,
  hidden: PropTypes.object,
  rowIndex: PropTypes.number,
  expandFirstRow: PropTypes.bool,
};

TableRowDesktop.defaultProps = {
  data: {},
  actions: null,
  beforeIconCollapseData: null,
  header: [],
  hidden: {},
  rowIndex: 0,
  expandFirstRow: false,
};
export default TableRowDesktop;

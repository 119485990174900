import {
  useContext, useEffect, useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import DownloadExcelButton from "components/DownloadExcelButton";
import CalibrationModal from "components/CalibrationModal";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import AlertDialog from "components/AlertDialog";
import { useDeleteCalibration } from "hooks/calibration";
import { SessionContext } from "modules/session/context";
import {
  COMPANY_SLUGS,
  EXCEL_FILES_NAME,
  OBJECT_KEYS,
  STATE,
} from "common/constants";
import { toast, handleMessages, MESSAGE_TYPES, HTTP_STATUS_RESPONSE, } from "components/Toast/functions";
import { getDataToDownload, getEvaluations } from "common/validators";
import {
  isCurrentCompanySlug, mainDownloadExcel, getSortingOrder,
} from "common/utils";
import { isEmpty } from "common/helpers";
import { getSurveyResultsToDownload, resetStateSurveyResultsToDownload } from "redux/actions/surveyProcessesActions";
import { getOne as getResultScaleById } from "redux/actions/resultScalesActions";
import { useCalibrationReasons } from "hooks/useCalibrationReasons";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import {
  getHeader, getRowsDesktop,
} from "./functions";
import {
  StyledGridContainer, StyledSubtitleText,
} from "../styles";

const Collaborators = (props) => {
  const {
    processId,
    pagination,
    handlePagination,
    handleSearch,
    query,
    setTableSort,
    isDownloadingParticipation = false,
    surveyProcess,
  } = props;

  const { t } = useTranslation(["collaborators", "performance", "survey"]);
  const {
    state: { user },
  } = useContext(SessionContext);

  const {
    list: surveyResultList,
    listTotal: surveyResultTotal,
    isLoadingList: surveyResultLoadingList,
    downloadList,
    isLoadingDownloadList,
  } = useSelector(({ surveyResultReducer }) => surveyResultReducer);

  const {
    isLoadingOne: isLoadingScale,
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyProcess) {
      const resultScaleId = surveyProcess?.result_scale_id;
      if (resultScaleId) {
        dispatch(getResultScaleById(resultScaleId));
      }
    }
    // eslint-disable-next-line
  }, [surveyProcess]);

  const evaluations = getEvaluations(surveyResultList, t);

  const enablePercent = !isCurrentCompanySlug(user, COMPANY_SLUGS.lahaus);

  useEffect(() => {
    if (downloadList && !isDownloadingParticipation) {
      const data = getDataToDownload(downloadList, enablePercent, resultScale?.result_scale_indices, t, "performance", surveyProcess.goals_active);
      mainDownloadExcel(data, EXCEL_FILES_NAME.performanceReview);
      dispatch(resetStateSurveyResultsToDownload());
    }
    // eslint-disable-next-line
  }, [downloadList, enablePercent, resultScale, t, dispatch]);

  const handleDownload = () => {
    toast(
      MESSAGE_TYPES.info,
      handleMessages(
        MESSAGE_TYPES.info,
        HTTP_STATUS_RESPONSE.ok,
        t,
        t("common:common.performance_results_download_message"),
      ),
    );

    dispatch(getSurveyResultsToDownload(processId, query, surveyResultTotal))
      .then(() => {
        toast(
          MESSAGE_TYPES.success,
          handleMessages(
            MESSAGE_TYPES.success,
            HTTP_STATUS_RESPONSE.ok,
            t,
            t("common:common.api_responses.success.download")
          ),
        );
      })
      .catch(() => {
        toast(
          MESSAGE_TYPES.error,
          handleMessages(
            MESSAGE_TYPES.error,
            HTTP_STATUS_RESPONSE.error,
            t,
          ),
        );
      });
  };

  const isDisabledDownload = isLoadingDownloadList || isLoadingScale || isEmpty(downloadList);

  const handleSort = (columnName, direction) => {
    setTableSort(getSortingOrder(columnName, direction));
  };

  const hasCalibration = surveyProcess?.calibration_activated;
  const hasCalibrationActivated = hasCalibration
  && !surveyProcess?.published
  && surveyProcess?.state === STATE.finished;
  const goalsActive = surveyProcess?.goals_active;

  // Calibration modal
  const [modal, setModal] = useState(false);
  const [result, setResult] = useState({});

  // Calibration Reason
  const { calibrationReasons, isLoading } = useCalibrationReasons();

  const handleOpenCalibrationModal = (resultSelected) => {
    setResult({});
    setModal(true);
    setResult(resultSelected);
  };

  const handleCloseCalibrationModal = () => {
    setModal(false);
    setResult({});
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [calibrationId, setCalibrationId] = useState(null);
  const { mutate: deleteCalibration } = useDeleteCalibration(t);

  const handleOpenRestoreModal = (id, resultSelected) => {
    setResult(resultSelected);
    setIsAlertOpen(true);
    setCalibrationId(id);
  };

  const handleRestore = () => {
    if (calibrationId) {
      deleteCalibration(calibrationId, {
        onSuccess: () => {
          result.calibration = null;
          const managerEvaluation = result.results_by_evaluation_type.find(
            (evalType) => evalType.evaluation_type === EVALUATION_TYPE.MANAGER,
          );
          if (managerEvaluation) {
            result.calibrated = false;
            result.calibratedResult = null;
          }
          setResult({});
          setIsAlertOpen(false);
        },
      });
    } else {
      setResult({});
      setIsAlertOpen(false);
    }
  };

  return (
    <div data-testid={ "collaborators-table-container" }>
      <StyledGridContainer container>
        <Grid item xs={ 12 }>
          <StyledSubtitleText>
            { `${t("performance:title")} - ${t("performance:dashboard.collaborators")}`}
          </StyledSubtitleText>
        </Grid>
      </StyledGridContainer>
      <Grid container spacing={ 4 }>
        <Grid item xs={ 12 }>
          <TablePaginationCollapse
            header={ getHeader(evaluations.map((e) => e.id), t, hasCalibration, goalsActive) }
            list={ surveyResultList }
            isLoading={ (surveyResultLoadingList
              && isLoadingScale
              && isLoading) || surveyResultList === null
              || !resultScale?.result_scale_indices }
            getRows={ {
              mobile: getRowsDesktop,
              desktop: getRowsDesktop,
            } }
            extraParam={ {
              processId,
              evaluations,
              hasCalibration,
              resultScale,
              handleOpenCalibrationModal,
              handleOpenRestoreModal,
              hasCalibrationActivated,
              goalsActive,
            } }
            handlePagination={ handlePagination }
            pagination={ pagination }
            total={ surveyResultTotal }
            rightOptions={ (
              <DownloadExcelButton
                onClick={ () => handleDownload() }
                isDisabled={ isDisabledDownload }
                isDownloadApart
              />
            ) }
            handleSearch={ handleSearch }
            sortAction={ handleSort }
            defaultOrderName={ OBJECT_KEYS.score }
          />
        </Grid>
      </Grid>
      <AlertDialog
        isOpen={ isAlertOpen }
        onClose={ () => setIsAlertOpen(false) }
        title={ t("common:calibration.confirm_restore") }
        onSubmit={ handleRestore }
      />
      {resultScale?.result_scale_indices && result?.id && calibrationReasons.length > 0
      && (
        <CalibrationModal
          isOpen={ modal }
          onClose={ handleCloseCalibrationModal }
          selectResult={ result }
          scale={ resultScale.result_scale_indices }
          isValidToUpdate={ hasCalibrationActivated }
        />
      )}
    </div>
  );
};

Collaborators.propTypes = {
  processId: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  setTableSort: PropTypes.func.isRequired,
  isDownloadingParticipation: PropTypes.bool,
  surveyProcess: PropTypes.object.isRequired,
};

export default Collaborators;

import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import GaussChart from "components_refactor/GaussChart";
import {
  getProcessColor,
} from "components_refactor/GaussChart/functions";
import Alert from "components_refactor/Alert";
import InfoBox from "components_refactor/InfoBox";
import TableGrid from "components/TableGrid";
import NoDataMessage from "components/NoDataMessage";
import { StyledPaperContainer } from "styledComponents/View";
import { getPercent } from "common/utils";
import { StyledColumn, StyledComparative } from "../../styles";

const ComparativeChart = ({ processes, scaleIndices, defaultProcessId }) => {
  const { t } = useTranslation("performance", "common");

  const [filteredProcesses, setFilteredProcesses] = useState(processes);

  // Memoize the onFilterProcesses function to ensure stable reference
  const handleFilterProcesses = useCallback((updatedProcesses) => {
    setFilteredProcesses(updatedProcesses);
  }, []);

  // Sort scale indices from highest to lowest based on 'top' value
  const sortedScaleIndices = [...scaleIndices].sort((a, b) => a.top - b.top);

  const header = [
    { id: "name", label: t("gauss_chart.table.header") },
    ...sortedScaleIndices.map((scale) => ({
      id: scale.result,
      label: scale.result,
    })),
  ];

  const rows = filteredProcesses.map((process) => ({
    name: (
      <div style={ { display: "flex", alignItems: "center" } }>
        <span
          style={ {
            width: "5px",
            height: "50px",
            backgroundColor: getProcessColor(process.name),
            display: "inline-block",
            marginRight: "8px",
          } }
        />
        {process.name}
      </div>
    ),
    ...sortedScaleIndices.reduce((acc, scale, index) => {
      const value = process.results_by_scale_indices[index + 1] || 0;
      acc[scale.result] = getPercent(value, true);
      return acc;
    }, {}),
  }));

  return (
    <StyledComparative container spacing={ 2 } alignItems={ "stretch" }>
      <StyledColumn item xs={ 12 } sm={ 6 }>
        <StyledPaperContainer marginBottom={ "0px" } padding={ "20px" } style={ { flex: 1 } }>
          <h3>{t("gauss_chart.title")}</h3>
          {processes?.length > 0
            ? (
              <GaussChart
                indices={ scaleIndices }
                processes={ processes }
                onFilterProcesses={ handleFilterProcesses }
                defaultProcessId={ defaultProcessId }
              />
            )
            : <NoDataMessage />}
          <InfoBox
            title={ t("gauss_chart.alert.title") }
            message={ t("gauss_chart.alert.message") }
          />
        </StyledPaperContainer>
      </StyledColumn>
      <StyledColumn item xs={ 12 } sm={ 6 }>
        <StyledPaperContainer marginBottom={ "0px" } padding={ "20px" } style={ { flex: 1 } }>
          <h3>{t("gauss_chart.table.title")}</h3>
          <TableGrid
            rows={ rows }
            header={ header }
            id={ "collaborator-table-grid" }
            paginationOptions={ {
              maxPerPage: 5,
              rowsRange: [5, 8, 10],
            } }
            isLoading={ false }
            hasSearch={ false }
          />
          <Grid item xs={ 12 } pt={ 2 }>
            <Alert
              type={ "success" }
              title={ t("gauss_chart.table.alert.title") }
              message={ t("gauss_chart.table.alert.message") }
            />
          </Grid>
        </StyledPaperContainer>
      </StyledColumn>
    </StyledComparative>
  );
};

ComparativeChart.propTypes = {
  processes: PropTypes.array.isRequired,
  scaleIndices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
      bottom: PropTypes.number.isRequired,
      result: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultProcessId: PropTypes.number.isRequired,
};

export default ComparativeChart;

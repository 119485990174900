import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import charts from "theme/charts";
import { isEmpty, isEqual, isNull } from "common/helpers";
import { INDEX } from "common/constants";
import Modal from "components/Modal";
import TableDesktop from "./components/TableDesktop";
import Paginator from "./components/Paginator";
import TableRowDesktop from "./components/TableRowDesktop";
import { errorTable, noDataTable, isNewInputRow } from "./functions";
import { StyledTable, StyledTableAddCell } from "./styles";

const TablePaginationCollapse = (props) => {
  const {
    list,
    isLoading,
    error,
    getRows,
    extraParam,
    pagination,
    handlePagination,
    total,
    modalOptions,
    actions,
    beforeIconCollapseData,
    isDisabledSearch,
    sortAction,
    defaultOrderName,
    expandFirstRow,
  } = props;
  const { t } = useTranslation(["common", "collaborators"]);

  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const parentId = actions && !isNull(actions.newIdElement)
    ? actions.newIdElement : actions?.parentId;

  const [hidden, setHidden] = useState({});

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleModalContent = (content) => {
    setModalContent(content);
  };

  const handleSort = (columnName, direction) => {
    if (sortAction) sortAction(columnName, direction);
  };

  const rowsOptions = [
    list,
    t,
    history,
    extraParam,
    isMobile,
    {
      value: isOpen,
      set: setIsOpen,
      handleModalContent,
      modalOptions,
    },
    actions?.module,
  ];

  const showContent = () => {
    const addRow = actions && isNewInputRow(actions, parentId) && (
      <TableRow>
        <StyledTableAddCell colSpan={ rowsOptions.length }>
          {actions?.getNewCustomRow(actions?.module, actions?.parentId)}
        </StyledTableAddCell>
      </TableRow>
    );

    if (error) {
      return (
        <>
          {addRow}
          <TableRow>
            <TableCell colSpan={ rowsOptions.length }>
              {errorTable(t)}
            </TableCell>
          </TableRow>
        </>
      );
    }
    if (isEmpty(list) && !isLoading) {
      return (
        <>
          {addRow}
          <TableRow>
            <TableCell colSpan={ rowsOptions.length }>
              {noDataTable()}
            </TableCell>
          </TableRow>
        </>
      );
    }

    const rows = getRows.desktop(...rowsOptions)?.map((item, index) => (
      <TableRowDesktop
        key={ `${item}-${index}` }
        item={ item }
        data={ list?.find((e) => isEqual(e.id, item[INDEX.zero].id)) }
        rowsOptions={ rowsOptions }
        actions={ actions }
        beforeIconCollapseData={ beforeIconCollapseData }
        hidden={ hidden }
        rowIndex={ index }
        expandFirstRow={ expandFirstRow }
        { ...props }
      />
    ));

    return (
      <>
        {rows}
        {addRow}
      </>
    );
  };

  const paginatorContent = (pagination || actions) && (
    <Paginator
      total={ total }
      pagination={ pagination }
      handlePagination={ handlePagination }
      actions={ actions }
    />
  );

  const modal = modalOptions && (
    <Modal
      isOpen={ isOpen }
      onClose={ handleCloseModal }
      title={ modalContent?.title }
    >
      <div>
        {modalContent?.children}
      </div>
    </Modal>
  );

  return (
    <StyledTable data-testid={ "table-pagination-collapse" }>
      {/* TODO: Mobile version */}
      <TableDesktop
        contentTable={ showContent }
        noBorder={ actions }
        isMobile={ isMobile }
        isDisabledSearch={ isDisabledSearch }
        handleSort={ handleSort }
        defaultOrderName={ defaultOrderName }
        hidden={ hidden }
        setHidden={ setHidden }
        { ...props }
      />
      { paginatorContent }
      { modal }
    </StyledTable>
  );
};

TablePaginationCollapse.propTypes = {
  getRows: PropTypes.object.isRequired,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  extraParam: PropTypes.object,
  pagination: PropTypes.number,
  handlePagination: PropTypes.func,
  total: PropTypes.number,
  modalOptions: PropTypes.array,
  actions: PropTypes.object,
  beforeIconCollapseData: PropTypes.string,
  isDisabledSearch: PropTypes.bool,
  sortAction: PropTypes.func,
  defaultOrderName: PropTypes.string,
  expandFirstRow: PropTypes.bool,
};

TablePaginationCollapse.defaultProps = {
  list: null,
  isLoading: false,
  error: "",
  extraParam: null,
  pagination: null,
  handlePagination: null,
  total: null,
  modalOptions: null,
  actions: null,
  beforeIconCollapseData: null,
  isDisabledSearch: false,
  sortAction: null,
  defaultOrderName: null,
  expandFirstRow: false,
};

export default TablePaginationCollapse;

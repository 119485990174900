import {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { isEqual as isEqualObject } from "lodash";
import PropTypes from "prop-types";
import {
  resetState,
  getEvaluationById,
  getProcessById,
  getListByEmployee as getSurveyProcessesByEmployee,
  updateEvaluationResult,
  saveEvaluationResultBySurveyProcessId,
  updateEvaluationResultBySurveyProcessId,
} from "redux/actions/surveyProcessesActions";
import { getList as getEvaluationScales } from "redux/actions/evaluationScaleActions";
import { getOne as getEvaluationResult, getEvaluationResultsByQuery } from "redux/actions/evaluationResultsActions";
import { getOne as getResultScaleById } from "redux/actions/resultScalesActions";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import { SessionContext } from "modules/session/context";
import SkeletonLoader from "components/SkeletonLoader";
import AlertModal from "components/AlertModal";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import {
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
} from "common/constants";
import {
  EVALUATION_TYPE,
} from "common/constants/surveyProcess";
import {
  getEmployeeId,
  historyPush,
} from "common/utils";
import { EVALUATION_STATES } from "views/Cover/functions";
import useComplexState from "hooks/utils/useComplexState";
import { getProcessTypes } from "../Planning/functions";
import { getEvaluationAnswers, isSimpleTypeEvaluation } from "../SurveyEvaluationsPreview/functions";
import { getResultId } from "./functions";
import { StyledDashboardContainer } from "./styles";
import EvaluationContent from "./components/EvaluationContent";

// TODO: Refactor - Something is not properly validated, as it breaks locally - new hires
// Change members by evaluation results
const EvaluationForm = ({ match }) => {
  const { t } = useTranslation("surveys", "common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const dispatch = useDispatch();
  const { id } = match.params;
  const history = useHistory();
  const location = useLocation();
  const {
    state: { user },
  } = useContext(SessionContext);
  const params = history.location.search;
  const evaluationResultParam = params?.split("evaluation_result")[1];
  const evaluationResultId = evaluationResultParam ? params?.split("=")[1] : null;
  const evaluatedParam = params?.split("collaborator")[1];
  const employeeIdToEvaluate = evaluatedParam ? params?.split("=")[1] : null;
  const processParam = params?.split("process_id")[1];
  const processId = processParam ? params?.split("=")[1] : null;
  const selectedEmployees = params?.split("selected")[1]?.split("=")[1]?.split(",")?.map((item) => item) || [];

  const {
    evaluation,
    isLoadingEvaluation,
    successProcess,
    loadingProcess,
  } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );

  const employeeId = getEmployeeId(user);
  const [processData, setProcessData] = useState(null);
  const [modal, setModal] = useState(false);
  const [slideAnswers, setSlideAnswers] = useState({});
  const [evaluationFormStates, setEvaluationFormStates] = useComplexState({
    teamMembers: null,
    isLoadingMembers: false,
  });
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    list: evaluationScales,
    isLoadingList: isLoadingEvaluationScales,
  } = useSelector(
    ({ evaluationScaleReducer }) => evaluationScaleReducer,
  );

  const {
    one: evaluationResult,
    isLoadingOne: isLoadingEvaluationResult,
  } = useSelector(
    ({ evaluationResultReducer }) => evaluationResultReducer,
  );
  const {
    listByEmployee,
    isLoadingListByEmployee,
    oneProcess: surveyProcess,
    isLoadingOneProcess: isLoadingSurveyProcess,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const {
    isLoadingOne: isLoadingScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  // Validate processId when evaluates all team
  useEffect(() => {
    if (processId) {
      setEvaluationFormStates({ isLoadingMembers: true });
    }
    // eslint-disable-next-line
  }, [dispatch, processId, getSurveyProcessesByEmployee]);

  useEffect(() => {
    if (processId && !isLoadingEvaluationScales) {
      const queryByProcess = {
        q: {
          id_in: [processId],
        },
      };
      dispatch(getSurveyProcessesByEmployee(queryByProcess));
    }
    // eslint-disable-next-line
  }, [dispatch, processId, getSurveyProcessesByEmployee]);

  // Evaluation result for each team member
  const addEmployeeInEvaluations = useCallback(async (members) => {
    const filteredMembers = selectedEmployees?.length > 0
      ? members?.filter((member) => selectedEmployees.includes(getResultId(member.link)))
      : members;
    const evaluationResultMembersId = filteredMembers?.map((member) => getResultId(member.link));
    const queryTeam = {
      q: {
        id_in: evaluationResultMembersId,
      },
    };
    const evaluationResultChildren = await getEvaluationResultsByQuery(queryTeam, processId);

    if (evaluationResultChildren && !evaluationResultChildren.errors) {
      const updatedMembers = filteredMembers.map((member) => {
        member.evaluationResult = evaluationResultChildren.find(
          (result) => result?.evaluated_id === member.id,
        );
        return member;
      });
      setEvaluationFormStates({
        teamMembers: updatedMembers,
        isLoadingMembers: false,
      });
    }
    // eslint-disable-next-line
  }, [setEvaluationFormStates, processId]);

  useEffect(() => {
    if (listByEmployee && !isLoadingListByEmployee && listByEmployee?.length > 0) {
      const managerEvaluation = listByEmployee[0]?.process_detail?.evaluations.find(
        (item) => item.evaluation_id === parseInt(id, 10),
      );
      if (managerEvaluation?.team_members?.length === 0) {
        setEvaluationFormStates({
          teamMembers: [],
          isLoadingMembers: false,
        });
      } else {
        addEmployeeInEvaluations(managerEvaluation?.team_members);
      }
    }
    // eslint-disable-next-line
  }, [listByEmployee, isLoadingListByEmployee]);

  useEffect(() => {
    if (!isLoadingEvaluationScales && !isLoadingEvaluation) {
      dispatch(getEvaluationScales());
      dispatch(getEvaluationById(id));
    }
    // eslint-disable-next-line
  }, [id]);

  // If the URL does not contain the associated evaluation result,
  // it redirects to the correct URL so that the form works properly.
  const updateUrlAndRedirect = useCallback((followUpEvaluationResultId) => {
    const newSearch = new URLSearchParams();
    newSearch.set("evaluation_result", followUpEvaluationResultId);
    historyPush(
      history,
      location.pathname,
      newSearch.toString(),
    );
  }, [history, location]);

  // If the URL does not contain the associated evaluation result,
  // it searches if it exists and if not, it creates it.
  // At the moment, this happens with follow-up surveys
  // because evaluation results are not created.
  const getOrCreateEvaluationResult = useCallback(async () => {
    const evaluatedId = employeeIdToEvaluate ? parseInt(employeeIdToEvaluate, 10) : employeeId;
    const evaluationAnswers = getEvaluationAnswers(
      true,
      [],
      evaluation,
      employeeId,
      evaluatedId,
      null,
    );
    // Search evaluation result
    const queryFollowUp = { q: {} };
    if (evaluation.type === EVALUATION_TYPE.OVERALL) {
      queryFollowUp.q.user_id_eq = user.id;
    } else {
      queryFollowUp.q.survey_result_employee_id_eq = evaluatedId;
      queryFollowUp.q.employee_id_eq = employeeId;
    }

    const followUpEvaluationResult = await getEvaluationResultsByQuery(
      queryFollowUp,
      evaluation.survey_process_id,
    );
    if (followUpEvaluationResult?.length === 0) {
      const evaluationResultCreatedId = await dispatch(saveEvaluationResultBySurveyProcessId(
        evaluationAnswers,
        evaluation.survey_process_id,
      ));
      if (evaluationResultCreatedId?.evaluation_result?.id) {
        updateUrlAndRedirect(evaluationResultCreatedId.evaluation_result.id);
      }
    } else {
      updateUrlAndRedirect(followUpEvaluationResult[0].id);
    }
  }, [
    dispatch,
    employeeId,
    employeeIdToEvaluate,
    evaluation,
    updateUrlAndRedirect,
    user,
  ]);

  useEffect(() => {
    if (evaluation && (employeeIdToEvaluate || !params)) {
      getOrCreateEvaluationResult();
    }
    // eslint-disable-next-line
  }, [evaluation]);

  const setDataByProcess = useCallback(() => {
    const processDetail = getProcessTypes(t).find(
      (process) => evaluation.survey_process_type === process.key,
    );
    setProcessData(processDetail);
    if (evaluation?.survey_process_id) {
      dispatch(getProcessById(evaluation.survey_process_id));
    }
    // eslint-disable-next-line
  }, [evaluation, t, dispatch]);

  useEffect(() => {
    if (evaluation && !isLoadingEvaluationResult) {
      setDataByProcess();
    }
    // eslint-disable-next-line
  }, [evaluation, isLoadingEvaluationResult]);

  const {
    control, handleSubmit, watch,
  } = useForm({
    defaultValues: {
      answers: {},
    },
  });

  const handleModalSubmit = () => {
    if (!processId
      && !isSimpleTypeEvaluation(evaluation.type)
      && !evaluationResultId
      && evaluationResult.evaluated_id === null
    ) {
      toast(MESSAGE_TYPES.warning, {
        title: t("alert.warning.title"),
        message: t("alert.warning.message"),
      });
    } else {
      setModal(!modal);
    }
  };

  // Save form data - draft state
  const sendDataFormat = useCallback(async (
    data,
    resultId,
    lastEvaluatedId,
    isDraft = false,
    answers = null,
    lastData = null,
    onSuccess = null,
  ) => {
    setIsDisabled(true);
    const evalResult = await dispatch(updateEvaluationResult(
      getEvaluationAnswers(isDraft, data, evaluation, employeeId, lastEvaluatedId, answers),
      evaluation.survey_process_id,
      resultId,
    ));
    if (evalResult && !evalResult.error && lastData) {
      const targetEvaluationResult = lastData?.evaluationResult || lastData;
      targetEvaluationResult.complete_answers = evalResult.complete_answers;
      targetEvaluationResult.open_questions = evalResult.open_questions;
      targetEvaluationResult.result = evalResult.result;
      targetEvaluationResult.score = evalResult.score;
      setIsDisabled(false);
      if (onSuccess) {
        onSuccess();
      }
    }
  }, [dispatch, employeeId, evaluation]);

  // Submit data - answered state
  const onSubmit = async () => {
    if (evaluationResult) {
      dispatch(updateEvaluationResultBySurveyProcessId(
        getEvaluationAnswers(
          false,
          [],
          evaluation,
          employeeId,
          evaluationResult?.evaluated_id,
          null,
        ),
        evaluation.survey_process_id,
        evaluationResultId,
      ));
    } else {
      const memberData = evaluationFormStates?.teamMembers;
      if (memberData) {
        const promises = memberData.map(async (member) => {
          await dispatch(updateEvaluationResultBySurveyProcessId(
            getEvaluationAnswers(
              false,
              [],
              evaluation,
              employeeId,
              member.evaluationResult?.evaluated_id,
              null,
            ),
            evaluation.survey_process_id,
            member.evaluationResult.id,
          ));
        });
        await Promise.all(promises);
      }
    }
    handleModalSubmit();
  };

  const prevWatchAllFieldsRef = useRef();
  const handleNextRef = useRef();
  const watchAllFields = watch();

  // Submit data every time an answer is provided
  const sendDataByQuestion = useCallback(async (dataByMember = null, memberId = null) => {
    if (processId) {
      if (memberId) {
        const memberData = evaluationFormStates.teamMembers.find(
          (member) => member.id === memberId,
        );
        if (dataByMember) {
          sendDataFormat(
            dataByMember,
            memberData.evaluationResult.id,
            memberId,
            true,
            memberData.evaluationResult.complete_answers,
            memberData,
          );
        }
      }
    } else {
      const data = dataByMember || control.getValues();
      if (evaluationResultId) {
        sendDataFormat(
          data,
          evaluationResultId,
          evaluationResult.evaluated_id,
          true,
          evaluationResult.complete_answers,
          evaluationResult,
          () => {
            if (handleNextRef.current) {
              handleNextRef.current();
            }
          },
        );
      }
    }
  }, [
    control,
    evaluationFormStates.teamMembers,
    evaluationResultId,
    processId,
    sendDataFormat,
    evaluationResult,
  ]);

  // Data by member - team
  const handleSendDataByQuestion = (memberData, memberId = null) => {
    sendDataByQuestion(memberData, memberId);
  };

  // Some inputs are managed through the useForm control, which means
  // that every time an option is selected, it saves from there
  useEffect(() => {
    if (Object.values(watchAllFields)[0] && !watchAllFields?.answers
      && !isEqualObject(prevWatchAllFieldsRef.current, watchAllFields)) {
      const [key, value] = Object.entries(watchAllFields)[0];
      setSlideAnswers({
        ...slideAnswers,
        [key]: value,
      });
      sendDataByQuestion();
      prevWatchAllFieldsRef.current = watchAllFields;
    }
    // eslint-disable-next-line
  }, [watchAllFields, sendDataByQuestion, slideAnswers]);

  const redirectToCover = useCallback(() => {
    historyPush(
      history,
      "/cover",
      `?process_id=${
        evaluation.survey_process_id
      }`,
    );
  }, [history, evaluation]);

  // If it is a common process, redirect to the process cover page,
  // otherwise redirect to the home page
  const redirectToCoverOrHome = useCallback(() => {
    let isFollowUpProcess = false;
    if (surveyProcess && surveyProcess[processData?.itemData]) {
      isFollowUpProcess = surveyProcess[processData?.itemData]?.follow_up_process_id !== null;
    }
    if (evaluation?.survey_process_id && !isFollowUpProcess) {
      redirectToCover();
    } else {
      historyPush(history, "/home");
    }
  }, [
    history,
    processData,
    surveyProcess,
    evaluation,
    redirectToCover,
  ]);

  // Reset data and redirect
  const resetStateAndRedirect = useCallback((isSubmit = false) => {
    const messageType = isSubmit ? MESSAGE_TYPES.success : MESSAGE_TYPES.info;
    toast(messageType, {
      title: t(`alert.${messageType}.title`),
      message: t(`alert.${messageType}.message`),
    });
    dispatch(resetState());
    redirectToCoverOrHome();
  }, [dispatch, redirectToCoverOrHome, t]);

  useEffect(() => {
    if (!isLoadingEvaluationResult && evaluationResult && evaluationResult?.evaluated_id) {
      if (evaluationResult.state === EVALUATION_STATES.answered) {
        resetStateAndRedirect();
      }
    }
  }, [
    evaluationResult,
    isLoadingEvaluationResult, resetStateAndRedirect,
  ]);

  useEffect(() => {
    if (evaluationResultId && !isLoadingEvaluationResult) {
      dispatch(getEvaluationResult(evaluationResultId));
    }
    // eslint-disable-next-line
  }, [evaluationResultId]);

  // If the evaluation is saved successfully with the answered status, then it will redirect
  useEffect(() => {
    if (successProcess) {
      resetStateAndRedirect(true);
    }
  }, [successProcess, resetStateAndRedirect]);

  // Return to cover process
  const handleToCoverOrHomeProcess = () => {
    redirectToCoverOrHome();
  };

  // get result scale
  useEffect(() => {
    if (surveyProcess) {
      const resultScaleId = surveyProcess[processData?.itemData]?.result_scale_id;
      if (resultScaleId) {
        dispatch(getResultScaleById(resultScaleId));
      }
    }
    // eslint-disable-next-line
  }, [surveyProcess]);

  const evaluationContent = (
    <StyledDashboardContainer>
      { evaluation !== null && evaluationScales !== null && processData !== null
      && (
        <>
          <EvaluationContent
            evaluation={ evaluation }
            evaluationScales={ evaluationScales }
            control={ control }
            handleModalSubmit={ handleModalSubmit }
            isDisabled={ isDisabled }
            handleSendDataByQuestion={ handleSendDataByQuestion }
            evaluationResult={ evaluationResult }
            isMobile={ isMobile }
            processId={ processId }
            processData={ processData }
            handleSubmit={ handleSubmit }
            slideAnswers={ slideAnswers }
            setSlideAnswers={ setSlideAnswers }
            loadingProcess={ loadingProcess }
            evaluationFormStates={ evaluationFormStates }
            handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
            handleNextRef={ handleNextRef }
            t={ t }
          />
          <AlertModal
            title={ t("common:common.modal_messages.sure_question") }
            text={ `${t("alert.message")} ${t("common:common.modal_messages.sure_text")}` }
            textDisagree={ t("common:common.modal_messages.no_cancel") }
            textAgree={ t("common:common.modal_messages.yes_confirm") }
            onClick={ onSubmit }
            open={ modal }
            handleClose={ handleModalSubmit }
            isLoading={ loadingProcess }
          />
        </>
      )}
    </StyledDashboardContainer>
  );

  const isLoadingForm = isLoadingEvaluation || isLoadingEvaluationScales || loadingProcess
  || isLoadingSurveyProcess || isLoadingEvaluationResult
  || isLoadingListByEmployee || evaluationFormStates.isLoadingMembers || isLoadingScale;

  return (
    isLoadingForm
      ? (
        <>
          <SkeletonLoader
            numberOfSkeletons={ SKELETONS_NUMBER.TWO }
            variant={ SKELETON_VARIANT.rectangular }
            height={ 150 }
            isInline={ false }
          />
          <SkeletonLoader
            numberOfSkeletons={ SKELETONS_NUMBER.FOUR }
            isInline={ false }
          />
        </>
      ) : evaluationContent
  );
};

EvaluationForm.propTypes = {
  match: PropTypes.object,
};

EvaluationForm.defaultProps = {
  match: { params: { id: null } },
};

export default EvaluationForm;

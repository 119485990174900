import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  OBJECT_KEYS,
} from "common/constants";
import {
  isEmpty, isEqual, isNull, isEmptyObject, isNullOrUndefined,
} from "common/helpers";
import starImg from "assets/images/performance/star.svg";
import ProfileImageDetail from "components/ProfileImageDetail";
import Select from "components/Select";
import NoDataMessage from "components/NoDataMessage";
import CircleLoader from "components_refactor/CircleLoader";
import {
  getTotalEvaluationTable,
  getStarRating,
} from "views_refactor/Profile/functions/performance";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import PerformanceChart from "components/PerformanceChart";
import AccordionRate from "./components/AccordionRate";
import {
  StyledGridContainer,
  StyledClassification,
  StyledGridContentContainer,
  StyledGridProfileContainer,
  StyledGridProfileImageContainer,
  StyledGridChartContainer,
  StyledGridFilter,
  StyledGridResult,
  StyledModal,
  StyledMenuContainer,
  StyledButtonGroup,
  StyledButton,
  StyledBoxResult,
  StyledLinkContainer,
  StyledActionButton,
  StyledFullWidthLink,
} from "./styles";

const PerformanceModal = (props) => {
  const {
    t,
    user,
    profileStates,
    performanceStates,
    surveyStates,
    handleChangeFilterPeriod,
    onCloseModals,
    collaborator,
    hasAccessDevelopmentPlan,
  } = props;

  const [selectedSection, setSelectedSection] = useState("results");

  const { isModalOpen } = profileStates;
  const { performanceData } = performanceStates;
  const { surveyResults } = surveyStates;

  const scaleIndices = performanceData?.result_scale_indices;
  const scaleMaxIndex = scaleIndices?.length;

  const emptyEvaluations = performanceStates.evaluationList?.length === 0;
  const hasGoals = performanceData?.complete_results_by_section?.some(
    (evaluation) => evaluation.section_type === "goals",
  );
  const hasFeedback = performanceData?.complete_results_by_section?.some(
    (evaluation) => evaluation?.open,
  );

  const userIsCollaborator = user.roles_name.every((role) => role === "collaborator");

  const scoreHistory = () => surveyResults.map((survey) => ({
    name: survey.survey_process.name,
    score: survey.score,
  }));

  const cardData = (evaluation) => {
    if (selectedSection === "goals" || selectedSection === "feedback") {
      return getTotalEvaluationTable(
        evaluation,
        evaluation.evaluation_results,
        performanceData,
        t,
        evaluation?.open,
        scaleMaxIndex,
        scaleIndices,
      );
    }

    return (
      <Box key={ evaluation.name } pb={ 3 }>
        <AccordionRate
          title={ evaluation.name }
          text={ evaluation.description }
          label={ evaluation.result }
          children={ getTotalEvaluationTable(
            evaluation,
            evaluation.evaluation_results,
            performanceData,
            t,
            evaluation?.open,
            scaleMaxIndex,
            scaleIndices,
          ) }
          isOpen={ evaluation?.open }
          scaleMaxIndex={ scaleMaxIndex }
          scaleIndices={ scaleIndices }
          score={ evaluation?.score }
          employeeName={ hasAccessDevelopmentPlan ? collaborator.full_name : null }
        />
      </Box>
    );
  };

  const evaluationCards = () => isEqual(
    performanceStates?.evaluationSelected,
    performanceData?.survey_process?.id,
  ) && !isEmpty(performanceData?.complete_results_by_section)
    && performanceData?.complete_results_by_section?.filter(
      (evaluation) => {
        if (evaluation.section_type === "goals" && selectedSection === "goals") {
          return true;
        }
        if (evaluation?.open && selectedSection === "feedback") {
          return true;
        }
        if (selectedSection === "results" && (!evaluation?.open && evaluation.section_type !== "goals")) {
          return true;
        }

        return false;
      },
    ).map(
      (evaluation) => cardData(evaluation),
    );

  const initialRender = isNull(surveyStates.surveyResults)
    || surveyStates.isLoadingSurveyResults
    || isNullOrUndefined(performanceData)
    ? (
      <CircleLoader />
    ) : (
      <StyledGridContainer container>
        <StyledGridFilter item xs={ 12 } sm={ 6 } md={ 7 }>
          <StyledClassification>
            <Box pr={ 1 }>
              <img
                src={ starImg }
                alt={ t("profile:modals.performance.evaluation") }
              />
            </Box>
            <Box>
              <Typography variant={ "h4" }>
                {t("profile:modals.performance.title")}
              </Typography>
            </Box>
          </StyledClassification>
        </StyledGridFilter>
        <StyledGridFilter item xs={ 12 } sm={ 6 } md={ 5 }>
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("profile:modals.performance.filterPeriod") }
            menuItems={ performanceStates.evaluationList }
            value={ performanceStates.evaluationSelected || "" }
            onChange={ handleChangeFilterPeriod }
            disabled={ isEmpty(performanceStates.evaluationList) }
          />
        </StyledGridFilter>
        <StyledGridProfileContainer container>
          <StyledGridProfileImageContainer item md={ 12 } lg={ 4 }>
            <Box marginBottom={ 2 }>
              <ProfileImageDetail
                collaborator={ collaborator }
                canViewProfile={ false }
                isShortVersion
                isModalVersion
                width={ 60 }
                height={ 60 }
              />
            </Box>
            { !userIsCollaborator && (
              <StyledBoxResult>
                <Typography variant={ "h4" }>{ t("profile:modals.performance.generalResult") }</Typography>
                {getStarRating(performanceData, scaleMaxIndex, scaleIndices)}
              </StyledBoxResult>
            )}
          </StyledGridProfileImageContainer>
          <StyledGridChartContainer item md={ 12 } lg={ 8 }>
            <PerformanceChart
              labels={ scoreHistory().map((h) => h.name) }
              data={ scoreHistory().map((h) => h.score) }
            />
          </StyledGridChartContainer>
        </StyledGridProfileContainer>
        <StyledGridContentContainer item xs={ 12 }>
          <StyledGridResult container>
            <Grid item xs={ 12 } md={ 3 }>
              <StyledBoxResult active={ selectedSection === "results" }>
                <h4>{t("profile:modals.performance.basicResult")}</h4>
                {getStarRating(performanceData?.scores?.basic, scaleMaxIndex, scaleIndices)}
              </StyledBoxResult>
            </Grid>
            { hasGoals && (
              <>
                <Divider orientation={ "vertical" } />
                <Grid item xs={ 12 } md={ 3 }>
                  <StyledBoxResult active={ selectedSection === "goals" }>
                    <h4>{t("profile:modals.performance.goalsResult")}</h4>
                    {getStarRating(performanceData?.scores?.goal, scaleMaxIndex, scaleIndices)}
                  </StyledBoxResult>
                </Grid>
              </>
            )}
          </StyledGridResult>
        </StyledGridContentContainer>
        <StyledMenuContainer>
          <StyledButtonGroup orientation={ "horizontal" }>
            <StyledButton
              active={ selectedSection === "results" }
              onClick={ () => setSelectedSection("results") }
            >
              {t("profile:modals.performance.results")}
            </StyledButton>
            {hasGoals && (
              <StyledButton
                active={ selectedSection === "goals" }
                onClick={ () => setSelectedSection("goals") }
              >
                {t("profile:modals.performance.goals")}
              </StyledButton>
            )}
            {hasFeedback && (
              <StyledButton
                active={ selectedSection === "feedback" }
                onClick={ () => setSelectedSection("feedback") }
              >
                {t("profile:modals.performance.feedback")}
              </StyledButton>
            )}
          </StyledButtonGroup>
          {hasAccessDevelopmentPlan
          && (
            <StyledLinkContainer>
              <StyledFullWidthLink to={ `/development-plan?employee-name=${collaborator?.full_name}` }>
                <StyledActionButton>
                  {t("common:view_development_plan")}
                </StyledActionButton>
              </StyledFullWidthLink>
            </StyledLinkContainer>
          )}
        </StyledMenuContainer>

        <StyledGridContentContainer item xs={ 12 }>
          {isEmptyObject(performanceData)
            || emptyEvaluations ? (
              <NoDataMessage customStyles={ "noDataCustom" } />
            ) : (
              <Grid container>
                <Grid item xs={ 12 }>
                  { isEmpty(evaluationCards()) ? <NoDataMessage customStyles={ "noDataCustom" } /> : evaluationCards() }
                </Grid>
              </Grid>
            )}
        </StyledGridContentContainer>
      </StyledGridContainer>
    );

  return (
    <StyledModal
      isOpen={ isModalOpen.performance }
      onClose={ () => onCloseModals() }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "800px" }>
        {initialRender}
      </StyledScrollBar>
    </StyledModal>
  );
};

PerformanceModal.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onCloseModals: PropTypes.func.isRequired,
  profileStates: PropTypes.object.isRequired,
  performanceStates: PropTypes.object.isRequired,
  surveyStates: PropTypes.object.isRequired,
  handleChangeFilterPeriod: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  hasAccessDevelopmentPlan: PropTypes.bool.isRequired,
};

export default PerformanceModal;

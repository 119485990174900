import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS, PAGINATION } from "common/constants";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";

import {
  RESET_STATE,
  RESET_STATE_RULES,
  GET_GOALS,
  GET_GOALS_LOADING,
  GET_GOALS_ERROR,
  GET_GENERAL_GOALS,
  GET_GOALS_RULES,
  GET_GOALS_RULES_LOADING,
  GET_GOALS_RULES_ERROR,
  PROCCESS_GOALS,
  PROCCESS_GOALS_LOADING,
  PROCCESS_GOALS_ERROR,
  RESET_PROCCESS_GOAL,
  RESET_STATE_ONE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
} from "../actionTypes/goals";

const URL = {
  main: "/employees",
  goals: "/goals",
  rules: "/goal_rules",
  participation: "/participation",
  getGoalsParticipationList: "/participation_download",
  asyncTask: "/async_task",
  goalEvaluationAnswer: "/goal_with_evaluation",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateRules = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_RULES,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_PROCCESS_GOAL,
  });
};

export const getListPaginated = (page, query) => async (dispatch) => {
  const GOAL_TYPE = query.q.end_date_gteq ? GET_GOALS : GET_GENERAL_GOALS;

  dispatch({
    type: GET_GOALS_LOADING,
  });

  const pagination = { size: PAGINATION.maxPerPage, number: page };

  try {
    const response = await configAxios.get(`${URL.goals}`, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GOAL_TYPE,
      payload: response.data.employees,
      total: response.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_GOALS_ERROR);
  }
};

export const getListTotal = (query, isGeneral = false) => async (dispatch) => {
  if (!isGeneral) {
    query.q.end_date_gteq = new Date();
  }
  try {
    const response = await configAxios.get(`${URL.goals}`, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    return response.data.employees;
  } catch (error) {
    errorHandler(error, dispatch, GET_GOALS_ERROR);
  }
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ONE,
  });
};

export const getGoalsByEmployeeId = (employeeId, isGeneral = false) => async (dispatch) => {
  const query = { q: {} };
  if (!isGeneral) {
    query.q.end_date_gteq = new Date();
  }

  dispatch({
    type: GET_ONE_LOADING,
  });

  try {
    const response = await configAxios.get(`${URL.main}/${employeeId}${URL.goals}`, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_ONE,
      payload: response.data.employee,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_ERROR);
  }
};

export const getRulesList = () => async (dispatch) => {
  dispatch({ type: GET_GOALS_RULES_LOADING });
  try {
    const response = await configAxios.get(`${URL.rules}`);
    dispatch({
      type: GET_GOALS_RULES,
      payload: response.data.goal_rules,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_GOALS_RULES_ERROR);
  }
};

export const create = (data) => async (dispatch) => {
  dispatch({ type: PROCCESS_GOALS_LOADING });
  try {
    const response = await configAxios.post(URL.goals, data);
    dispatch({
      type: PROCCESS_GOALS,
      payload: response.data.goal,
    });
    return response.data.goal;
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_GOALS_ERROR);
  }
};

export const deleteItem = (goalId) => async (dispatch) => {
  dispatch({ type: PROCCESS_GOALS_LOADING });
  try {
    const response = await configAxios.delete(`${URL.goals}/${goalId}`);
    dispatch({
      type: PROCCESS_GOALS,
      payload: response.data.goal,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_GOALS_ERROR);
  }
};

export const update = (data, goalId) => async (dispatch) => {
  dispatch({ type: PROCCESS_GOALS_LOADING });
  try {
    const response = await configAxios.put(`${URL.goals}/${goalId}`, data);
    dispatch({
      type: PROCCESS_GOALS,
      payload: response.data.goal,
    });
    return response.data.goal;
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_GOALS_ERROR);
  }
};

export const getGoalById = async (goalId) => {
  const response = await configAxios.get(`${URL.goals}/${goalId}`, {
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data.goal;
};

export const getGoalsParticipation = async (filterQuery) => {
  const response = await configAxios.get(`${URL.goals}${URL.participation}`, {
    params: { q: filterQuery.q },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });

  return response.data;
};

export const getGoalsParticipationList = async (filterQuery) => {
  const response = await configAxios.get(`${URL.goals}${URL.getGoalsParticipationList}`, {
    params: { ...filterQuery },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });

  return response.data?.collaborators;
};

export const sendGoalsReminder = async (data) => {
  const response = await configAxios.post(`${URL.goals}/send_reminder`, data);
  return response.data.async_task.id;
};

export const getStatus = (taskId) => async (dispatch) => {
  try {
    const response = await configAxios.get(`${URL.asyncTask}/${taskId}`);
    return response.data.async_task;
  } catch (error) {
    errorHandler(error.status, dispatch, PROCCESS_GOALS_ERROR);
  }
};

export const getGoalByGoalEvaluationAnswerId = async (goalEvaluationAnswerId) => {
  const response = await configAxios.get(`${URL.goals}/${URL.goalEvaluationAnswer}`, {
    params: { goal_evaluation_answer_id: goalEvaluationAnswerId },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data.goal;
};

import styled from "styled-components";
import palette from "theme/palette";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const StyledSkill = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${palette.background.lightGray};

  .top-row {
    display: flex;
    align-items: flex-start;
  }

  img {
    width: 28px;
    margin-right: 8px;
  }

  .tooltip {
    margin-top: 4px;
    text-align: flex-start;
    width: 500px;
    word-wrap: break-word;
    white-space: normal;
  }
`;

export const StyledTypography = styled(Typography)`
  font-weight: 500px !important;
  font-size: 18px;
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 400px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: flex-start;
    width: 100%;
  }
`;

import {
  ROLES, COMPANY_SLUGS, MODULES, MODULE_ACTIONS,
} from "common/constants";

const MC_ACCESS = {
  general: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.VACATION_ADMIN,
    ROLES.ONBOARDING_ADMIN,
  ],
  profile: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.VACATION_ADMIN,
    ROLES.ONBOARDING_ADMIN,
  ],
  analytics: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
  ],
  talentManagementWithNewHires: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.ONBOARDING_ADMIN,
  ],
  talentManagement: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
  ],
  goals: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
  ],
};

export const ROLE_MODULES = [
  {
    slug: MODULES.home,
    roles: [
      ROLES.ADMIN,
      ROLES.ADMIN_NALA,
      ROLES.COLLABORATOR,
      ROLES.MANAGER,
      ROLES.TALENT_MANAGER,
      ROLES.VACATION_ADMIN,
      ROLES.ONBOARDING_ADMIN,
    ],
  },
  {
    slug: MODULES.profile,
    roles: [
      ROLES.ADMIN,
      ROLES.ADMIN_NALA,
      ROLES.COLLABORATOR,
      ROLES.MANAGER,
      ROLES.TALENT_MANAGER,
      ROLES.VACATION_ADMIN,
      ROLES.ONBOARDING_ADMIN,
    ],
  },
  {
    slug: MODULES.organizationChart,
    roles: [
      ROLES.ADMIN,
      ROLES.ADMIN_NALA,
      ROLES.COLLABORATOR,
      ROLES.MANAGER,
      ROLES.TALENT_MANAGER,
      ROLES.VACATION_ADMIN,
      ROLES.ONBOARDING_ADMIN,
    ],
  },
  {
    slug: MODULES.acknowledgments,
    roles: [
      ROLES.ADMIN,
      ROLES.ADMIN_NALA,
      ROLES.COLLABORATOR,
      ROLES.MANAGER,
      ROLES.TALENT_MANAGER,
      ROLES.VACATION_ADMIN,
      ROLES.ONBOARDING_ADMIN,
    ],
    companiesValidation: [
      {
        company: COMPANY_SLUGS.mt_ad,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.mt_lp,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.mt_mc,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.add_cdo,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.idl,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.em_acmas,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.mt_hf,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.unab,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.bvi,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.bbi,
        roles: MC_ACCESS.general,
      },
      {
        company: "nalabot",
        roles: [
          ROLES.ADMIN,
          ROLES.TALENT_MANAGER,
        ],
      },
      // Used for testing
      {
        company: "test",
        roles: MC_ACCESS.general,
      },
    ],
  },
  {
    slug: MODULES.goals,
    roles: [
      ROLES.ADMIN,
      ROLES.ADMIN_NALA,
      ROLES.COLLABORATOR,
      ROLES.MANAGER,
      ROLES.TALENT_MANAGER,
    ],
    companiesValidation: [
      {
        company: COMPANY_SLUGS.mt_ad,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.mt_lp,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.mt_mc,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.add_cdo,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.idl,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.em_acmas,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.mt_hf,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.unab,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.bvi,
        roles: MC_ACCESS.goals,
      },
      {
        company: COMPANY_SLUGS.bbi,
        roles: MC_ACCESS.goals,
      },
      {
        company: "nalabot",
        roles: [
          ROLES.ADMIN,
          ROLES.TALENT_MANAGER,
        ],
      },
      // Used for testing
      {
        company: "test",
        roles: MC_ACCESS.goals,
      },
      {
        company: "restrictedCompany",
        actionRestrictions: {
          roles: MC_ACCESS.goals,
          actions: [MODULE_ACTIONS.update],
        },
      },
    ],
  },
  {
    slug: MODULES.developmentPlan,
    roles: [
      ROLES.ADMIN,
      ROLES.ADMIN_NALA,
      ROLES.COLLABORATOR,
      ROLES.MANAGER,
      ROLES.TALENT_MANAGER,
      ROLES.VACATION_ADMIN,
      ROLES.ONBOARDING_ADMIN,
    ],
    companiesValidation: [
      {
        company: COMPANY_SLUGS.mt_ad,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.mt_lp,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.mt_mc,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.add_cdo,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.idl,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.em_acmas,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.mt_hf,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.unab,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.bvi,
        roles: MC_ACCESS.general,
      },
      {
        company: COMPANY_SLUGS.bbi,
        roles: MC_ACCESS.general,
      },
      {
        company: "nalabot",
        roles: [
          ROLES.ADMIN,
          ROLES.SUCCESSION_ADMIN,
        ],
      },
      // Used for testing
      {
        company: "test",
        roles: MC_ACCESS.general,
      },
    ],
  },
];

export const handleModulesValidation = (
  can,
  userRoles,
  companiesValidation,
  moduleSlug,
  companySlug,
  actions,
) => {
  const matchingCompanyValidation = companiesValidation.find(
    (companyValidation) => companyValidation.company === companySlug,
  );

  let validActions = [...actions];

  if (matchingCompanyValidation) {
    const { roles: companyRoles, actionRestrictions } = matchingCompanyValidation;

    if (actionRestrictions) {
      const hasAccessRole = userRoles.some((role) => actionRestrictions.roles.includes(role));

      if (!hasAccessRole) {
        validActions = validActions.filter(
          (action) => !actionRestrictions.actions.includes(action),
        );
      }
    }

    if (companyRoles && !companyRoles.some((role) => userRoles.includes(role))) {
      validActions = [];
    }
  }

  validActions.forEach((action) => {
    can(action, moduleSlug);
  });
};

import React from "react";
import PropTypes from "prop-types";
import { orderByDesc } from "common/helpers";
import StarRating from "components/StarRating";
import { Box, Typography } from "@mui/material";
import {
  ALIGN_ITEMS, TYPES_RESULTS, VARIANT, CLASSIFICATION_RANK, CLASSIFICATION_RANK_WITH_DESCRIPTION,
} from "common/constants";
import largeIcon from "assets/images/potential/large.svg";
import balancedIcon from "assets/images/potential/balanced.svg";
import deepIcon from "assets/images/potential/deep.svg";
import { POTENTIAL_COLORS } from "theme/palette";
import TooltipIcon from "components/TooltipIcon";
import {
  StyledSkill,
  StyledTypography,
  StyledBox,
} from "./styles";

const ResultScale = ({
  resultScale, isPotentialProcess, isPerformanceProcess, t,
}) => {
  const resultScaleIndicesDesc = orderByDesc(resultScale.result_scale_indices, "top");

  const skillInformationLevels = {
    high: {
      icon: <img alt={ TYPES_RESULTS.large } src={ largeIcon } />,
      color: POTENTIAL_COLORS.purple,
      tooltip: t("potential:potential-measures.comprehensive"),
    },
    medium: {
      icon: <img alt={ TYPES_RESULTS.balanced } src={ balancedIcon } />,
      color: POTENTIAL_COLORS.orange,
      tooltip: t("potential:potential-measures.balanced"),
    },
    low: {
      icon: <img alt={ TYPES_RESULTS.deep } src={ deepIcon } />,
      color: POTENTIAL_COLORS.green,
      tooltip: t("potential:potential-measures.deep"),
    },
  };

  const tooltipDescription = () => [
    {
      key: CLASSIFICATION_RANK.SUPER_STAR,
      value: (
        <p>{t("performance:classificationRankDescription.superStar.text")}</p>
      ),
    },
    {
      key: CLASSIFICATION_RANK.STAR,
      value: (
        <p>{t("performance:classificationRankDescription.star.text")}</p>
      ),
    },
    {
      key: CLASSIFICATION_RANK.CONSISTENT,
      value: (
        <p>{t("performance:classificationRankDescription.consistent.text")}</p>
      ),
    },
    {
      key: CLASSIFICATION_RANK.IN_DEVELOPMENT,
      value: (
        <p>{t("performance:classificationRankDescription.inDevelopment.text")}</p>
      ),
    },
    {
      key: CLASSIFICATION_RANK.NOT_CONSISTENT,
      value: (
        <p>{t("performance:classificationRankDescription.notConsistent.text")}</p>
      ),
    },
  ];

  const getTooltipDescription = (label) => tooltipDescription(t).find(
    (item) => item.key === CLASSIFICATION_RANK_WITH_DESCRIPTION[label],
  );

  const renderPerformanceProcess = () => resultScaleIndicesDesc.map((row, index) => {
    const resultScaleLength = resultScaleIndicesDesc.length;
    const description = row.description || getTooltipDescription(row.result)?.value;
    return (
      <Box
        key={ row.id || index }
        mb={ 1 }
        display={ ALIGN_ITEMS.flex }
        alignItems={ ALIGN_ITEMS.center }
        justifyContent={ ALIGN_ITEMS.flexEnd }
      >
        <StyledBox>
          <StarRating
            name={ `star-rating-${index}` }
            value={ resultScaleLength - index }
            isReadOnly
            maxRating={ resultScaleLength }
            precision={ 1 }
            hasNoFormattedValue
            max={ resultScaleLength }
          />
          <Box ml={ 1 }>
            <Typography>
              {row.result}
            </Typography>
          </Box>
          {description
        && (
          <TooltipIcon
            title={ description }
            isHelpIcon
          />
        )}
        </StyledBox>
      </Box>
    );
  });

  const renderPotentialProcess = () => resultScaleIndicesDesc.map((row, index) => {
    const skillInfo = skillInformationLevels[row?.nine_box_result];
    if (!skillInfo) return null;
    return (
      <Box
        key={ row.id || index }
        mb={ 1 }
        display={ ALIGN_ITEMS.flex }
        alignItems={ ALIGN_ITEMS.center }
        justifyContent={ ALIGN_ITEMS.flexEnd }
      >
        <StyledSkill color={ skillInfo.color }>
          <div className={ "top-row" }>
            {skillInfo.icon}
            <StyledTypography>{row?.result}</StyledTypography>
          </div>
          <Typography className={ "tooltip" } variant={ VARIANT.bodyTwo }>{row.description || skillInfo.tooltip}</Typography>
        </StyledSkill>
      </Box>
    );
  });

  return (
    <>
      {isPerformanceProcess && renderPerformanceProcess()}
      {isPotentialProcess && renderPotentialProcess()}
    </>
  );
};

ResultScale.propTypes = {
  resultScale: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isPotentialProcess: PropTypes.bool.isRequired,
  isPerformanceProcess: PropTypes.bool.isRequired,
};

export default ResultScale;

import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import StarRating from "components/StarRating";
import { skillInformationLevels } from "../../functions";
import { StyledLabel, StyledSkill } from "../../styles";

const CurrentScore = (props) => {
  const {
    scaleMax, resultIndex, t, type, isValidToUpdate,
  } = props;

  const nineBoxResult = resultIndex?.nine_box_result;
  const skillInfo = nineBoxResult ? skillInformationLevels(t)[nineBoxResult] : null;

  // TODO: StarRating score?
  const defaultPerformanceResult = (
    <StarRating
      name={ `default-star-rating-${resultIndex?.result}` }
      value={ resultIndex?.position }
      isReadOnly
      maxRating={ scaleMax }
      max={ scaleMax }
      precision={ 1 }
      hasNoFormattedValue
      detail={ resultIndex?.result }
    />
  );

  const defaultPotentialResult = (
    <StyledSkill color={ skillInfo?.color }>
      {skillInfo?.icon}
      <p>{resultIndex?.result}</p>
    </StyledSkill>
  );

  return (
    <>
      <StyledLabel>{`${t("calibration.modal.current")}:`}</StyledLabel>
      {isValidToUpdate
        ? (
          <Select value={ resultIndex?.id } displayEmpty fullWidth disabled sx={ { height: "65px" } }>
            <MenuItem key={ resultIndex?.id } value={ resultIndex?.id }>
              <div style={ { display: "flex", alignItems: "center" } }>
                {type === "performance" && defaultPerformanceResult}
                {type === "potential" && defaultPotentialResult}
              </div>
            </MenuItem>
          </Select>
        )
        : (
          <div style={ { display: "flex", alignItems: "center", justifyContent: !isValidToUpdate && "right" } }>
            {type === "performance" && defaultPerformanceResult}
            {type === "potential" && defaultPotentialResult}
          </div>
        )}
    </>
  );
};

CurrentScore.propTypes = {
  scaleMax: PropTypes.number.isRequired,
  resultIndex: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    result: PropTypes.string.isRequired,
    position: PropTypes.number,
    nine_box_result: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["performance", "potential"]).isRequired,
  isValidToUpdate: PropTypes.bool.isRequired,
};

export default CurrentScore;

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "components/Button";
import InputTag from "components/InputTag";
import InputTextController from "components/InputTextController";
import AutoCompleteCreable from "components/AutocompleteCreable";
import AlertDialog from "components/AlertDialog";
import InputText from "components/InputText";
import CheckboxInput from "components/CheckboxInput";
import { ReactComponent as SuccessionIcon } from "assets/images/icons/succession.svg";
import { isEmpty, isNull, isEqual } from "common/helpers";
import { getFormattedTags } from "common/utils";
import { STATES } from "common/constants/agreements";
import {
  BUTTON_STYLE_TYPES,
  INPUT_TYPE,
  OBJECT_KEYS,
  PAGINATION,
  SIZE,
  VARIANT,
} from "common/constants";
import { create as createCategory } from "redux/actions/agreements/agreementCategoriesActions";
import { getListPaginated, getSuccessionPlansByEmployeeId } from "redux/actions/collaboratorActions";
import { create as createAgreement, update as updateAgreement } from "redux/actions/agreements/agreementActions";
import { getCategoryId, getState } from "views/DevelopmentPlan/functions";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledLabel,
  StyledAddLinkGrid,
  StyledText,
  StyledDialogContent,
  StyledInputForm,
  StyledAlert,
  StyledContainer,
  StyledTextContainer,
} from "./styles";

const AddModal = (props) => {
  const {
    isOpen,
    onClose,
    handleSubmit,
    reset,
    register,
    control,
    employeeId,
    selectAgreement,
    isValidToPrivateAgreements,
    isValidToSuccessionPlan,
    agreementName,
  } = props;
  const { t } = useTranslation(["developmentPlan", "common"]);
  const [dialog, setDialog] = useState(false);
  const [inputTextValue, setInputTextValue] = useState("");
  const [searchFilter, setSearchFilter] = useState();
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [changeTransition, setChangeTransition] = useState(false);
  const [supportLinks, setSupportLinks] = useState([
    { title: "", url: "" },
  ]);
  const [defaultValue, setDefaultValue] = useState({
    category: null,
    state: null,
  });

  const {
    list: agreementsCategoriesList,
    isLoadingProcessAgreementCategory,
    successProcessAgreementCategory,
  } = useSelector(({ agreementsCategoryReducer }) => agreementsCategoryReducer);

  const {
    list: statesList,
  } = useSelector(({ agreementsStatesReducer }) => agreementsStatesReducer);

  const {
    list: collaboratorList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    data: successionPlansByEmployee,
    isLoading: isLoadingSuccessionPlans,
  } = useQuery(["successionPlansByEmployee", employeeId], () => getSuccessionPlansByEmployeeId(employeeId), {
    staleTime: Infinity,
    enabled: isValidToSuccessionPlan,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectAgreement && statesList) {
      const commonFields = {
        "agreement.title": selectAgreement.title,
        "agreement.description": selectAgreement.description,
        "agreement.ending_date": selectAgreement.ending_date,
        "agreement.starting_date": selectAgreement.starting_date,
      };
      if (isValidToPrivateAgreements) {
        commonFields["agreement.hidden"] = selectAgreement?.hidden;
      }
      reset(commonFields);
      const initialValues = {
        category: selectAgreement.agreement_category,
        state: getState(statesList, selectAgreement.state, OBJECT_KEYS.id),
      };

      if (isValidToSuccessionPlan) {
        const successionPlanInfo = selectAgreement?.succession_plan;
        if (successionPlanInfo) {
          successionPlanInfo.name = `${successionPlanInfo?.position_name} (${successionPlanInfo?.organization_unit_complete_path})`;
        }
        initialValues.succession = successionPlanInfo;
      }

      setDefaultValue(initialValues);

      setSupportLinks(isEmpty(selectAgreement.urls) ? [{ title: "", url: "" }] : selectAgreement.urls);
      setInputTagsSelected(selectAgreement.involved_employees.map((e) => ({
        id: e.id,
        label: e.full_name,
      })));
      setTagsList(selectAgreement.involved_employees.map((e) => e.id));
    } else {
      reset({
        "agreement.title": agreementName || "",
        "agreement.description": "",
        "agreement.ending_date": moment(),
        "agreement.starting_date": moment(),
      });
      const initialValues = {
        category: null,
        state: getState(statesList, STATES.pending, OBJECT_KEYS.id),
      };

      if (isValidToSuccessionPlan) {
        initialValues.succession = null;
      }

      setDefaultValue(initialValues);
      setSupportLinks([{ title: "", url: "" }]);
      setInputTagsSelected([]);
      setTagsList([]);
    }
  }, [dispatch, selectAgreement, reset, statesList, agreementName]);

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        active_in: [true],
      },
    };
    dispatch(getListPaginated(PAGINATION.next, query));
  }, [searchFilter, dispatch]);

  const onAutocompleteChange = (nameOfAttr) => (prop, event, newValue) => {
    if (newValue && nameOfAttr in newValue) {
      setDefaultValue({
        ...defaultValue,
        [prop]: newValue[nameOfAttr],
      });
    }
    if (isEqual(prop, OBJECT_KEYS.state)) {
      setChangeTransition(true);
    }
  };

  const handleDialog = (option) => {
    setDialog(option);
  };

  const handleDialogSubmit = (text) => {
    setInputTextValue(text);
    handleDialog(true);
  };

  const handleNew = async () => {
    const data = {
      name: inputTextValue,
    };
    dispatch(createCategory(data));
  };

  useEffect(() => {
    if (successProcessAgreementCategory) {
      setDefaultValue({
        ...defaultValue,
        category: inputTextValue,
      });
      setDialog(false);
    }
  }, [successProcessAgreementCategory, defaultValue, inputTextValue, t, dispatch]);

  const options = {
    pending: [
      { state: STATES.finished },
    ],
    finished: [],
  };

  const getCompleteStatesOptions = () => {
    let statesOptions = statesList;
    if (selectAgreement) {
      const optionsByState = options[selectAgreement?.state];
      statesOptions = optionsByState.map((item) => (statesList.find((e) => e.id === item.state)));
    }
    statesOptions.forEach((item) => {
      item[OBJECT_KEYS.name] = t(`developmentPlan:states.${item?.id}`);
    });
    return statesOptions;
  };

  const handleChange = (index) => (prop, event) => {
    const links = [...supportLinks];
    links[index] = { ...supportLinks[index], [event.target.name]: event.target.value };
    setSupportLinks(links);
  };

  const addNewSupportLink = () => {
    setSupportLinks([...supportLinks, { title: "", url: "" }]);
  };

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  const handleTags = (tags) => {
    setTagsList(tags?.map((tag) => tag.id));
  };

  const onSubmit = async () => {
    const data = control.getValues();
    const { agreement } = data;
    agreement.employee_id = employeeId;
    agreement.agreement_category_id = getCategoryId(defaultValue.category, agreementsCategoriesList);
    agreement.involved_employee_ids = tagsList;
    agreement.urls_attributes = supportLinks.filter((e) => !(isEmpty(e.title) && isEmpty(e.url)));
    if (defaultValue?.succession) {
      agreement.succession_plan_id = getCategoryId(defaultValue.succession, successionPlansByEmployee);
    }
    if (selectAgreement) {
      if (changeTransition) {
        agreement.state_transition = getState(statesList, defaultValue.state, OBJECT_KEYS.name).transition;
      }
      dispatch(updateAgreement(selectAgreement.id, data));
    } else {
      dispatch(createAgreement(data));
    }
  };

  const contentLinks = (
    <Grid container spacing={ 2 }>
      {supportLinks?.map((link, index) => (
        <>
          <Grid item xs={ 12 } md={ 4 }>
            <InputText
              variant={ VARIANT.outlined }
              name={ OBJECT_KEYS.title }
              label={ t("tables:headers.title") }
              onChange={ handleChange(index) }
              value={ link.title }
              fullWidth
              size={ "small" }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 8 }>
            <InputText
              variant={ VARIANT.outlined }
              name={ OBJECT_KEYS.url }
              label={ <StyledText>{ OBJECT_KEYS.url }</StyledText> }
              onChange={ handleChange(index) }
              value={ link.url }
              fullWidth
              size={ "small" }
            />
          </Grid>
        </>
      ))}
      <StyledAddLinkGrid item xs={ 12 }>
        <Button
          onClick={ addNewSupportLink }
          variant={ VARIANT.text }
        >
          { t("addLink") }
        </Button>
      </StyledAddLinkGrid>
    </Grid>
  );

  const hasSuccessionPlan = isValidToSuccessionPlan && !isLoadingSuccessionPlans
  && successionPlansByEmployee && successionPlansByEmployee.length !== 0;

  return (
    <div data-testid={ "add-modal-agreement-component" }>
      <Dialog
        open={ isOpen }
        maxWidth={ SIZE.lg }
        fullWidth
      >
        <form onSubmit={ handleSubmit(onSubmit) }>
          <StyledDialogTitle disableTypography>
            <h2>{ t("newAgreement") }</h2>
            <IconButton onClick={ onClose }>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent>
            <Grid container spacing={ 2 }>
              {isValidToPrivateAgreements
              && (
                <Grid item xs={ 12 } md={ hasSuccessionPlan ? 6 : 12 }>
                  <FormControlLabel
                    control={ (
                      <CheckboxInput
                        name={ "agreement.hidden" }
                        inputRef={ register }
                        defaultChecked={ selectAgreement?.hidden }
                      />
                    ) }
                    label={ t("developmentPlan:hidden") }
                  />
                </Grid>
              )}
              { hasSuccessionPlan && (
                <Grid item xs={ 12 } md={ 6 }>
                  <StyledContainer>
                    <SuccessionIcon />
                    <StyledTextContainer>
                      <StyledLabel>
                        {t("successions:succession_plan.title")}
                        <span>
                          {` ${t("successions:succession_plan.position_unit")}`}
                        </span>
                      </StyledLabel>
                      <StyledAlert>{t("successions:succession_plan.description_add")}</StyledAlert>
                    </StyledTextContainer>
                  </StyledContainer>
                  <AutoCompleteCreable
                    id={ "succession" }
                    name={ "succession" }
                    options={ successionPlansByEmployee }
                    register={ register }
                    value={ defaultValue.succession }
                    onChange={ onAutocompleteChange(OBJECT_KEYS.name) }
                    nameOfAttr={ OBJECT_KEYS.name }
                    size={ "small" }
                  />
                </Grid>
              )}
              <Grid item xs={ 12 } md={ 4 }>
                <StyledLabel>
                  { t("tables:headers.title") }
                </StyledLabel>
                <InputTextController
                  type={ INPUT_TYPE.text }
                  control={ control }
                  name={ "agreement.title" }
                  required
                  size={ "small" }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 2 }>
                <StyledLabel>
                  { t("tables:headers.start_date") }
                </StyledLabel>
                <StyledInputForm
                  type={ INPUT_TYPE.date }
                  id={ OBJECT_KEYS.startingDate }
                  control={ control }
                  openTo={ OBJECT_KEYS.day }
                  value={ moment() }
                  views={ ["year", "month", "day"] }
                  name={ "agreement.starting_date" }
                  required
                  size={ "small" }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 2 }>
                <StyledLabel>
                  { t("tables:headers.review-date") }
                </StyledLabel>
                <StyledInputForm
                  type={ INPUT_TYPE.date }
                  id={ OBJECT_KEYS.end_date }
                  control={ control }
                  openTo={ OBJECT_KEYS.day }
                  value={ moment() }
                  views={ ["year", "month", "day"] }
                  name={ "agreement.ending_date" }
                  required
                  size={ "small" }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 2 }>
                <StyledLabel>
                  { t("tables:headers.category") }
                </StyledLabel>
                <AutoCompleteCreable
                  id={ OBJECT_KEYS.category }
                  options={ agreementsCategoriesList }
                  register={ register }
                  value={ defaultValue.category }
                  onChange={ onAutocompleteChange(OBJECT_KEYS.name) }
                  nameOfAttr={ OBJECT_KEYS.name }
                  addNew={ {
                    handleNew: handleDialogSubmit,
                    text: t("common:common.add_new_category"),
                    prop: OBJECT_KEYS.category,
                  } }
                  isRequired
                  size={ "small" }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 2 }>
                <StyledLabel>
                  { t("tables:headers.state") }
                </StyledLabel>
                <AutoCompleteCreable
                  id={ OBJECT_KEYS.state }
                  options={ getCompleteStatesOptions() }
                  register={ register }
                  value={ defaultValue.state }
                  nameOfAttr={ OBJECT_KEYS.name }
                  onChange={ onAutocompleteChange("name") }
                  disabled={ isNull(selectAgreement) }
                  size={ "small" }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <StyledLabel>
                  { t("tables:headers.description") }
                </StyledLabel>
                <InputTextController
                  type={ INPUT_TYPE.text }
                  control={ control }
                  multiline={ 5 }
                  name={ "agreement.description" }
                  size={ "small" }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <StyledLabel>
                  { t("labels.supportLinks") }
                </StyledLabel>
                { contentLinks }
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <StyledLabel>
                  { t("labels.peopleInvolved") }
                </StyledLabel>
                <InputTag
                  id={ "involved_employee_ids" }
                  size={ SIZE.small }
                  itemsSelected={ inputTagsSelected }
                  placeholder={ t("searchCollaborators") }
                  register={ register }
                  onInputTextChange={ handleInputText }
                  onChange={ handleTags }
                  data={ getFormattedTags(collaboratorList, OBJECT_KEYS.fullname) }
                  hasCheckbox
                />
              </Grid>
            </Grid>
          </StyledDialogContent>
          <StyledDialogActions>
            <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ onClose }>
              { t("Onboarding:cancelButton") }
            </Button>
            <Button
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              type={ BUTTON_STYLE_TYPES.SUBMIT }
            >
              { t("common:common.save") }
            </Button>
          </StyledDialogActions>
        </form>
      </Dialog>
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => handleDialog(false) }
        title={ `${t("common:common.add_new_category")}: ${inputTextValue}` }
        message={ t("common:common.modal_messages.sure_text") }
        onSubmit={ handleNew }
        buttons={ {
          isLoading: isLoadingProcessAgreementCategory,
        } }
      />
    </div>
  );
};

AddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  employeeId: PropTypes.number.isRequired,
  selectAgreement: PropTypes.object,
  isValidToPrivateAgreements: PropTypes.bool,
  isValidToSuccessionPlan: PropTypes.bool,
  agreementName: PropTypes.string,
};

AddModal.defaultProps = {
  selectAgreement: null,
  isValidToPrivateAgreements: false,
  isValidToSuccessionPlan: false,
  agreementName: "",
};

export default AddModal;

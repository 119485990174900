import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import theme from "theme";
import palette from "theme/palette";
import constants from "../constants";

export const StyledContainer = styled(Card)`
  padding: ${(props) => props.padding};
  height: ${(props) => props.height || "100%"};
  margin-top: ${(props) => props.marginTop};
`;

export const StyledTitle = styled(Typography)`
  font-size: ${(prop) => `${prop.size}px` || constants.fontSize.medium};
  font-weight: ${constants.fontWeight.medium};
  margin-bottom: ${`${theme.spacing(2)}px`};
  text-align: ${(prop) => prop.textAlign || "left"};
`;

export const StyledSubtitle = styled(Typography)`
  font-weight: ${ (props) => props.fontWeight || 500 };
  font-size: ${ (props) => props.fontSize || "18px" };
  line-height: ${ (props) => props.lineHeigth || "21px" };
  color: ${ (props) => props.color || palette.subtitle };
`;

export const StyledGridFlex = styled(Grid)`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`;

export const StyledTooltipTitle = styled(Typography)`
  font-size: ${constants.fontSize.small};
  font-weight: ${constants.fontWeight.medium};
  margin-left: ${`${theme.spacing(1)}px`};
  margin-top: ${`${theme.spacing(1)}px`};
`;

export const StyledTooltipText = styled.p`
  font-size: ${constants.fontSize.small};
  font-weight: ${constants.fontWeight.normal};
`;

export const StyledSectionTitle = styled(Typography)`
  font-size: ${constants.fontSize.standar};
  font-weight: ${constants.fontWeight.medium};
  margin-bottom: ${`${theme.spacing(1)}px`};
`;

export const StyledDashboardContainer = styled(Box)`
  padding: 32px;
  margin-bottom: 25px;
  .custom-select {
    background-color: ${theme.palette.white};
    padding: 10px 0 10px 0;
    label + & {
      margin-top: 8;
    }
  }
  @media (max-width: 600px) {
    padding: 16px 12px;
  }
`;

export const StyledPaperContainer = styled(Paper)`
  padding: ${(props) => (props.padding || "32px")};
  margin-top: ${(props) => (props.marginTop || "16px")};
  margin-bottom: ${(props) => (props.marginBottom || "25px")};
  min-height: ${(props) => (props.minheight || "")};
  max-height: ${(props) => (props.maxheight || "")};
`;

export const StyledGridContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const StyledLink = styled.div`
  color: ${(props) => props.color || palette.text.link};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledGridEnd = styled(Grid)`
  text-align: right;
`;

export const StyledSimpleDashboardContainer = styled(Box)`
  padding: 32px;
  @media (max-width: 600px) {
    padding: 16px 12px;
  }
`;

import styled from "styled-components";

export const ExplanatoryContainer = styled.div`
  border: 1px solid #0d47a1;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #0d47a1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 8px;

  p {
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
    color: #333;
  }
`;

import { useMutation } from "react-query";
import {
  getDistributionByScale,
} from "redux/actions/surveyProcessesActions";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";

export const useSurveyProcess = () => {
  const mutation = useMutation(
    ({ query }) => getDistributionByScale(query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

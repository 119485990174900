import { Link } from "react-router-dom";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import theme from "theme";

export const StyledAccordionContainer = styled(Accordion)`
  box-shadow: ${theme.palette.boxShadow.default};
  &. Mui-expanded{
    margin: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${theme.palette.background.light};
  border-radius: 4px;
  .icon {
    color: ${theme.palette.text.link};
  }
  .disabledIcon {
    opacity: 0;
  }
  .details {
    color: ${theme.palette.text.darkPurple};
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border: 0;
  padding: 0;
  margin-top: 20px;
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  padding: 10px 0;
  font-size: 13px;
  margin-top: 2px;
  letter-spacing: 0.02857em;
  font-weight: 500;
  color: ${theme.palette.background.purpleBox};
`;

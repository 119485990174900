import { getGoalsParticipationList } from "redux/actions/goalsActions";
import { mainDownloadExcel } from "common/utils";
import { ROLES_NAMES, ROLES } from "common/constants";

const goalsParticipationExcelData = async (t, data) => data.map((collaborator) => ({
  [t("goals:participation.file.full_name")]: collaborator.full_name,
  [t("goals:participation.file.email")]: collaborator.email,
  [t("goals:participation.file.position_name")]: collaborator.position_name,
  [t("goals:participation.file.role")]: collaborator.roles_name.includes(ROLES.MANAGER) ? t(ROLES_NAMES.manager) : t(ROLES_NAMES.collaborator),
  [t("goals:participation.file.area_name")]: collaborator.area_name,
  [t("goals:participation.file.manager_name")]: collaborator.manager_name,
  [t("goals:participation.file.manager_email")]: collaborator.manager_email,
  [t("goals:participation.file.active_goals")]: collaborator.active_goals,
}));

const downloadGoalsParticipationExcel = async (t, filterQuery) => {
  const data = [];

  let currentPage = 1;
  const pageSize = 50;

  let hasMorePages = true;

  while (hasMorePages) {
    const query = {
      q: {
        ...filterQuery.q,
      },
      page: {
        number: currentPage,
        size: pageSize,
      },
    };

    // eslint-disable-next-line no-await-in-loop
    const responseData = await getGoalsParticipationList(query);

    if (!responseData || responseData.length === 0) {
      hasMorePages = false;
    } else {
      data.push(...responseData);
      if (responseData.length < pageSize) {
        hasMorePages = false;
      } else {
        currentPage += 1;
      }
    }
  }

  const excelData = await goalsParticipationExcelData(t, data);

  mainDownloadExcel(excelData, t("goals:participation.file.title"));
};

export default downloadGoalsParticipationExcel;

//  NOTE: all commented code will be used as the refactor progresses
import axios from "axios";
import i18n from "i18next";
import { isUndefined, isEqual } from "common/helpers";

// import {
//   // TOKEN_KEY, TOKEN_STRING,
// } from "../config";
import {
  API_URL_RAILS_V1,
  LOCAL_STORAGE_NAMES,
  TRUE,
  API_URL_PY,
} from "common/constants";
import {
  getItemFromLocalStorage,
  getValidDate,
  getCryptoConfig,
  setInLocalStorageAsync,
} from "common/utils";

// const TOKEN = localStorage.getItem(TOKEN_KEY);
const baseURL = API_URL_RAILS_V1; //  FIXME: we should use a proxy
const pythonURL = API_URL_PY;

// BIGNOTE: deleteItem from orgUnitsActions is beeing used as old arch.

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const pythonInstance = axios.create({
  baseURL: pythonURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// if (TOKEN) {
//   instance.defaults.headers.common.Authorization = TOKEN_STRING + TOKEN;
// } else {
const addInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const validDate = getValidDate();
    const { params, headers } = config;
    const locale = i18n.language;
    const accessToken = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.accessToken);
    const client = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.client);
    const uid = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.uid);
    const user = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user);

    config.params = {
      ...params,
      locale,
    };

    config.headers = {
      ...headers,
      "date-time": validDate,
      "request-sign": getCryptoConfig(validDate),
      "access-token": accessToken,
      client,
      uid,
    };

    if (user?.companies && isUndefined(user?.company?.is_main)) {
      config.params.company_id = user?.company_id;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(async (response) => {
    const accessToken = response.headers["access-token"];
    const lastVersion = response.headers.version;
    const oldVersion = localStorage.getItem(LOCAL_STORAGE_NAMES.version);

    if (accessToken) {
      setInLocalStorageAsync(LOCAL_STORAGE_NAMES.accessToken, accessToken);
    }
    if (!oldVersion && lastVersion) {
      localStorage.setItem(LOCAL_STORAGE_NAMES.version, lastVersion);
    } else if (lastVersion && !isEqual(lastVersion, oldVersion)) {
      setInLocalStorageAsync(LOCAL_STORAGE_NAMES.newUpdate, TRUE);
    }
    return response;
  });
};

addInterceptors(instance);
addInterceptors(pythonInstance);
// }

// export const updateToken = (token) => {
//   instance.defaults.headers.common.Authorization = TOKEN_STRING + token;
//   instance.interceptors.request.use((config) => {
//     return config;
//   });
// };

export { instance, pythonInstance };
export default instance;

import i18n from "i18next";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { addNotification } from "redux/actions/notifications/notificationsActions";
import {
  ERROR,
  TOAST_ERROR_DURATION,
  LOCAL_STORAGE_NAMES,
  SIGN_IN_URLS,
} from "common/constants";
import { setInLocalStorage } from "common/utils";
import { isUndefined } from "common/helpers";

const extractMessageFromError = (error) => {
  let messageFromApi = null;

  if (error?.response && error.response?.data) {
    const { data } = error.response;
    if (data) {
      const propError = data?.errors && Object.keys(data.errors)[0];
      messageFromApi = data.detail
        || data.error
        || data.message
        || (data.errors && data.errors[0])
        || (propError && data.errors[propError][0]);
    }
  } else if (error?.message) {
    messageFromApi = error.message;
  } else if (error) {
    messageFromApi = error.toString();
  }

  return messageFromApi;
};

const errorHandler = (error, dispatch, typeAction, additionalData = {}) => {
  if (error?.response?.status === 401) {
    const isSignIn = SIGN_IN_URLS.find((url) => window.location.href.includes(url));
    if (isUndefined(isSignIn)) {
      setInLocalStorage(LOCAL_STORAGE_NAMES.logOutError, true);
      window.location.replace("/");
      return;
    }
  }

  const status = error?.response?.status || 500;
  const messageFromApi = extractMessageFromError(error);

  const messageError = {
    title: i18n.t("common:common.api_responses.error.title"),
    message: messageFromApi || i18n.t(`common:common.api_responses.error.${status}`),
  };

  dispatch(
    addNotification({
      type: ERROR,
      duration: TOAST_ERROR_DURATION,
      title: messageError.title,
      description: messageError.message,
    }),
  );

  toast(MESSAGE_TYPES.error, messageError);

  setTimeout(() => {
    dispatch({
      ...additionalData,
      type: typeAction,
      payload: null,
    });
  }, 2000);
};

export default errorHandler;
